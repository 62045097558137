import {Shortcut} from "@mui/icons-material";
import {Button,Grid,Typography,lighten} from "@mui/material";
import CardExample from "components/Card";
import CardRevenue from "components/CardRevenue";
import Div from "ui-component/layouts/components/Div";
import Chart from "react-apexcharts";
import CardGraph from "components/CardGraph";
import CardQuick from "components/CardQuick";
import JumboContent from "components/CardContent";
import {Timeline,TimelineConnector,TimelineContent,TimelineDot,TimelineItem,TimelineSeparator} from "@mui/lab";

export default function FinanceiroDashboard() {
    return (
        <Grid container spacing={4}>
            {/* <Grid item xs={12} className="header-title">
                <Typography variant="h1" gutterBottom>
                    Atalhos
                </Typography>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={2.5} xl={2}>
                        <Div sx={{height: '130px',width: '100%',border: '1px solid black',borderRadius: '5px',padding: 2,display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
                            <Shortcut />
                            <Typography variant="h6">Gerar Boleto de cobrança</Typography>
                        </Div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2.5} xl={2}>
                        <Div sx={{height: '130px',width: '100%',border: '1px solid black',borderRadius: '5px',padding: 2,display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
                            <Shortcut />
                            <Typography variant="h6">Cadastrar chave Pix</Typography>
                        </Div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2.5} xl={2}>
                        <Div sx={{height: '130px',width: '100%',border: '1px solid black',borderRadius: '5px',padding: 2,display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
                            <Shortcut />
                            <Typography variant="h6">Gerar Pix de cobrança</Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12} className="header-title">
                <Typography variant="h1" gutterBottom>
                    Resumo
                </Typography>
            </Grid> */}
            <Grid item xs={12} md={12} lg={8.7} xl={9}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <CardRevenue title='Saldo Total' value={'R$ 70.965,41'} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <CardGraph sx={{color: "common.white",borderRadius: 4,boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'}} title={'Entradas'} value={'R$ 26.228,56'} bgColor={[lighten('#00a29b',0.4),'#00a29b']} trending='line' />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <CardGraph sx={{color: "common.white",borderRadius: 4,boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'}} title={'Saídas'} value={'R$ 4.713,80'} bgColor={[lighten('#b5b72a',0.4),'#b5b72a']} trending='line' />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <CardExample title='Resumo para os próximos 10 dias'>
                            <Chart
                                options={{
                                    colors: ['#612466','#e82276','#00a29b','#b5b72a'],
                                    plotOptions: {
                                        bar: {
                                            borderRadius: 2,
                                            dataLabels: {
                                                position: 'center', // top, center, bottom
                                                orientation: 'vertical', // horizontal, vertical
                                            },
                                        }
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    },
                                    chart: {
                                        id: "GraficoFaturamentoAno",
                                        background: '#fff',
                                        redrawOnParentResize: true
                                    },
                                    xaxis: {
                                        categories: ['21/10','22/10','23/10','24/10','25/10','26/10','27/10','28/10','29/10','30/10'],
                                    }
                                }}
                                series={[
                                    {
                                        name: "Previsto/Pagar",
                                        data: [1000,1000,1000,1000,1000,1000,3000,3500,4000,4000],
                                    },
                                    {
                                        name: 'Previsto/Receber',
                                        data: [2000,1000,1000,1000,1000,1000,2000,1500,1000,1000],
                                    },
                                    {
                                        name: 'Saldo Previsto',
                                        data: [1000,500,500,500,500,500,500,500,500,500],
                                    }
                                ]}
                                type="line"
                                width="100%"
                                height={420}
                            />
                        </CardExample>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={3.3} xl={3}>
                <Grid container spacing={3.5}>
                    <Grid item xs={12} md={4} lg={12} xl={12}>
                        <CardGraph sx={{color: "common.white",borderRadius: 4,boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'}} title={'Contas a Pagar'} value={'R$ 2.713,80'} bgColor={[lighten('#e82276',0.4),'#e82276']} trending='line' />
                    </Grid>
                    <Grid item xs={12} md={4} lg={12} xl={12}>
                        <CardGraph sx={{color: "common.white",borderRadius: 4,boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'}} title={'Contas a Receber'} value={'R$ 10.713,80'} bgColor={[lighten('#612466',0.4),'#612466']} trending='line' />
                    </Grid>
                    <Grid item xs={12} md={4} lg={12} xl={12}>
                        <CardQuick noWrapper>
                            <JumboContent sx={{p: 3}}>
                                <Typography variant={"h4"} color={"text.secondary"} mb={2}>Movimentações</Typography>
                                <Timeline sx={{
                                    m: 0,
                                    p: 0,
                                }}>
                                    {
                                        [{
                                            title: 'Recebimento de boleto',
                                            subTitle: 'R$ 1.000,00',
                                            color: '#00a29b',
                                            icon: <Shortcut />,
                                            date: 'Hoje'
                                        },{
                                            title: 'Pagamento de boleto',
                                            subTitle: 'R$ 1.000,00',
                                            color: '#e82276',
                                            icon: <Shortcut />,
                                            date: '20/10/2024'
                                        },{
                                            title: 'Recebimento de boleto',
                                            subTitle: 'R$ 1.000,00',
                                            color: '#00a29b',
                                            icon: <Shortcut />,
                                            date: '19/10/2024'
                                        }].map((schedule,index) => (
                                            <TimelineItem
                                                key={index}
                                                sx={{
                                                    '&::before': {
                                                        display: 'none'
                                                    }
                                                }}
                                            >
                                                <TimelineSeparator>
                                                    <TimelineDot sx={{
                                                        m: theme => theme.spacing(.5,0),
                                                        bgcolor: schedule.color,
                                                        width: 20,
                                                        height: 20,
                                                        boxShadow: '0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)',
                                                    }}
                                                    >
                                                        {schedule.icon}
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{
                                                        p: theme => theme.spacing(.5,0),
                                                        ml: 2.5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Div sx={{display: 'flex',flexDirection: 'column'}}>
                                                        {
                                                            schedule.title&&
                                                            <Typography variant={"h6"} color={"text.primary"}
                                                                lineHeight={1.5}>{schedule.title}</Typography>
                                                        }
                                                        {
                                                            schedule.subTitle&&
                                                            <Typography variant={"body1"} color={"text.secondary"} fontSize={12}
                                                                mb={2}>{schedule.subTitle}</Typography>
                                                        }
                                                    </Div>
                                                    {
                                                        schedule.date&&
                                                        <Typography ml='auto' variant={"body1"} color={"text.secondary"} fontSize={12}
                                                            mb={2}>{schedule.date}</Typography>
                                                    }
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))
                                    }
                                </Timeline>
                                <Button sx={{mt: 2}} variant="outlined" color="primary" fullWidth>Ver todas as movimentações</Button>
                            </JumboContent>
                        </CardQuick>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}