import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import Assistant from '@mui/icons-material/Assistant';
import {Box,Button,Chip,Fade,FormControl,FormHelperText,ListItemIcon,ListItemText,MenuItem,MenuList,Popper,Typography} from '@mui/material';
import {Add,ArrowBack,Circle,Close} from '@mui/icons-material';
import {IconAlertCircle} from '@tabler/icons';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import './quill.css';
import {Controller, useForm} from 'react-hook-form';
import InputTextField from 'components/InputTextField';

const FeedbackButtonCss=styled(IconButton)(() => ({
    position: 'fixed',
    display: 'none',
    bottom: 16,
    right: 16,
    padding: '2px',
}));

const FeedbackButton=() => {
    const [open,setOpen]=React.useState(false);
    const [open2,setOpen2]=React.useState(false);
    const [open3,setOpen3]=React.useState(false);
    const [anchorEl,setAnchorEl]=React.useState(null);
    const [anchorEl2,setAnchorEl2]=React.useState(null);
    const [anchorEl3,setAnchorEl3]=React.useState(null);
    const [selectForm,setSelectForm]=React.useState(null);
    const [categoria,setCategoria]=React.useState(null);
    const [prioridade,setPrioridade]=React.useState(null);

    const handleClick=(event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen=open&&Boolean(anchorEl);
    const canBeOpen2=open2&&Boolean(anchorEl2);
    const canBeOpen3=open3&&Boolean(anchorEl3);
    const id=canBeOpen? 'transition-popper':undefined;
    const id2=canBeOpen2? 'transition-popper2':undefined;
    const id3=canBeOpen3? 'transition-popper3':undefined;

    const {
        control,
        formState: {errors}
      }=useForm({
        defaultValues: {
            titulo: '',
            contrato_descricao: '',
        },
      });
    

    return (
        <>
            <Tooltip title="Feedbacks" placement="top">
                <FeedbackButtonCss aria-describedby={id} color="primary" size="large" onClick={handleClick}>
                    <Assistant />
                </FeedbackButtonCss>
            </Tooltip>
            <Popper placement='top-end' id={id} open={open} anchorEl={anchorEl} transition sx={{zIndex: 9999}}>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{border: 1,bgcolor: '#7300f0',width: '400px',borderRadius: '5px'}}>
                            {
                                selectForm? (
                                    <Box aria-description='header' sx={{display: 'flex',justifyContent: 'space-between', minHeight: '50px', p: 2}}>
                                        <IconButton sx={{p: 0, height: '25px'}} onClick={() => setSelectForm(null)}>
                                            <ArrowBack />
                                        </IconButton>
                                        <IconButton sx={{p: 0, height: '25px'}} onClick={() => setOpen(false)}>
                                            <Close />
                                        </IconButton>
                                    </Box>
                                ):(
                                    <Box aria-description='header' sx={{display: 'flex',justifyContent: 'end', minHeight: '50px', p: 2}}>
                                        <IconButton sx={{p: 0, height: '25px'}} onClick={() => setOpen(false)}>
                                            <Close />
                                        </IconButton>
                                    </Box>
                                )
                            }
                            {
                                !selectForm&&(
                                    <Box sx={{p: 2,marginBottom: '30px'}}>
                                        <Typography variant='h2' sx={{p: 1}}>Feedbacks</Typography>
                                        <Typography variant='subtitle1' sx={{p: 1}}>Utilize esta ferramenta para reportar erros e resolver problemas para criarmos uma experiência cada vez melhor para você!</Typography>
                                    </Box>
                                )
                            }
                            <Box sx={{backgroundColor: 'background.default',borderRadius: '10px 10px 0px 0px',p: 2,position: 'relative'}}>
                                {
                                    !selectForm? (
                                        <>
                                            <Box onClick={() => setSelectForm('default')} sx={{cursor: 'pointer',position: 'absolute',bottom: '80%',display: 'flex',alignItems: 'center',justifyContent: 'space-evenly',boxShadow: '0 4px 15px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .1), inset 0 2px 0 0 hsla(0, 0%, 81%, .5)',backgroundColor: '#fff',borderRadius: '5px'}}>
                                                <Box>
                                                    <Typography variant='h4' sx={{p: 1,paddingBottom: '2px',color: 'black'}}>Reportar um problema</Typography>
                                                    <Typography variant='subtitle1' sx={{p: 1,paddingTop: '2px',width: '340px',color: '#425466'}}>Envie sua sugestão ou reporte um problema para que possamos melhorar a experiência do usuário.</Typography>
                                                </Box>
                                                <Box>
                                                    <IconAlertCircle color='black' />
                                                </Box>
                                            </Box>
                                            <Box sx={{display: 'flex',justifyContent: 'space-between',marginTop: '50px'}}>
                                                <Box onClick={() => setSelectForm('bug')} sx={{cursor: 'pointer',display: 'flex',alignItems: 'center',justifyContent: 'space-evenly',boxShadow: '0 4px 15px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .1), inset 0 2px 0 0 hsla(0, 0%, 81%, .5)',backgroundColor: '#fff',borderRadius: '5px',position: 'relative'}}>
                                                    <Box sx={{textAlign: 'center'}}>
                                                        <Typography variant='h4' sx={{p: 1,paddingBottom: '2px',color: 'black'}}>Bug Plataforma</Typography>
                                                        <Typography variant='subtitle1' sx={{p: 1,paddingTop: '2px',width: '173px',color: '#425466'}}>Encontrou um bug na plataforma?</Typography>
                                                    </Box>
                                                    <Box sx={{position: 'absolute',top: 0,right: 0}}>
                                                        <IconAlertCircle color='black' />
                                                    </Box>
                                                </Box>
                                                <Box onClick={() => setSelectForm('erro')} sx={{cursor: 'pointer',display: 'flex',alignItems: 'center',justifyContent: 'space-evenly',boxShadow: '0 4px 15px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .1), inset 0 2px 0 0 hsla(0, 0%, 81%, .5)',backgroundColor: '#fff',borderRadius: '5px',position: 'relative'}}>
                                                    <Box sx={{textAlign: 'center'}}>
                                                        <Typography variant='h4' sx={{p: 1,paddingBottom: '2px',color: 'black'}}>Erro ortográfico</Typography>
                                                        <Typography variant='subtitle1' sx={{p: 1,paddingTop: '2px',width: '173px',color: '#425466'}}>Encontrou um erro ortográfico?</Typography>
                                                    </Box>
                                                    <Box sx={{position: 'absolute',top: 0,right: 0}}>
                                                        <IconAlertCircle color='black' />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box onClick={() => setSelectForm('entregas')} sx={{cursor: 'pointer',marginTop: '13px',display: 'flex',alignItems: 'center',justifyContent: 'space-evenly',boxShadow: '0 4px 15px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .1), inset 0 2px 0 0 hsla(0, 0%, 81%, .5)',backgroundColor: '#fff',borderRadius: '5px'}}>
                                                <Box>
                                                    <Typography variant='h4' sx={{p: 1,paddingBottom: '2px',color: 'black'}}>Entregas</Typography>
                                                    <Typography variant='subtitle1' sx={{p: 1,paddingTop: '2px',width: '340px',color: '#425466'}}>Acompanhe as entregas de novas funcionalidades e melhorias na plataforma.</Typography>
                                                </Box>
                                                <Box>
                                                    <IconAlertCircle color='black' />
                                                </Box>
                                            </Box>
                                        </>
                                    ):(
                                        <>
                                            <Box sx={{display: 'flex',gap: 2,flexWrap: 'wrap'}}>
                                                <Chip sx={{height: '25px'}} icon={<Add />} label={categoria? `Categoria | ${categoria}`:'Categoria'} onClick={
                                                    (event) => {
                                                        setAnchorEl2(event.currentTarget);
                                                        setOpen2((previousOpen) => !previousOpen);
                                                    }
                                                } />
                                                <Popper placement='bottom' id={id2} open={open2} anchorEl={anchorEl2} transition sx={{zIndex: 9999}}>
                                                    {({TransitionProps}) => (
                                                        <Fade {...TransitionProps} timeout={350}>
                                                            <MenuList sx={{bgcolor: 'background.default'}}>
                                                                <MenuItem onClick={() => {setCategoria('Bug - Plataforma'); setOpen2(false)}}>
                                                                    <ListItemIcon>
                                                                        <Circle />
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        Bug - Plataforma
                                                                    </ListItemText>
                                                                </MenuItem>
                                                            </MenuList>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                                <Chip sx={{height: '25px'}} icon={<Add />} label={prioridade? `Prioridade | ${prioridade}`:'Prioridade'} onClick={
                                                    (event) => {
                                                        setAnchorEl3(event.currentTarget);
                                                        setOpen3((previousOpen) => !previousOpen);
                                                    }
                                                } />
                                                <Popper placement='bottom' id={id3} open={open3} anchorEl={anchorEl3} transition sx={{zIndex: 9999}}>
                                                    {({TransitionProps}) => (
                                                        <Fade {...TransitionProps} timeout={350}>
                                                            <MenuList sx={{bgcolor: 'background.default'}}>
                                                                <MenuItem onClick={() => {setPrioridade('Não é importante'); setOpen3(false)}}>
                                                                    <ListItemIcon>
                                                                        <Circle />
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        Não é importante
                                                                    </ListItemText>
                                                                </MenuItem>
                                                            </MenuList>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                            </Box>
                                            <Box sx={{marginTop: '25px'}}>
                                                <InputTextField label='Título' name='titulo' control={control} rules={{required: 'Campo obrigatório'}} error={errors.titulo} />
                                            </Box>
                                            <Box>
                                                <FormControl fullWidth error={!!errors.contrato_descricao}>
                                                    <Controller
                                                        name="contrato_descricao"
                                                        control={control}
                                                        rules={{required: 'Campo obrigatório'}}
                                                        externalError={errors.contrato_descricao}
                                                        render={({field: {onChange,onBlur,value,ref}}) => (
                                                            <ReactQuill
                                                                style={{border: errors.contrato_descricao? '1px solid #f44336':null}}
                                                                className={errors.contrato_descricao? 'quill-feedback quill-error':'quill-feedback'}
                                                                placeholder={'Escreva o que você deseja reportar...'}
                                                                modules={{
                                                                    toolbar: [
                                                                        [{header: '1'},{header: '2'},{font: []}],
                                                                        [{size: []}],
                                                                        ['bold','italic','underline','strike','blockquote'],
                                                                        [
                                                                            {list: 'ordered'},
                                                                            {list: 'bullet'},
                                                                            {indent: '-1'},
                                                                            {indent: '+1'}
                                                                        ],
                                                                        ['link'],
                                                                        ['clean']
                                                                    ],
                                                                    clipboard: {matchVisual: false}
                                                                }}
                                                                formats={[
                                                                    'font','size',
                                                                    'bold','italic','underline','strike',
                                                                    'color','background',
                                                                    'script',
                                                                    'header','blockquote','code-block',
                                                                    'indent','list',
                                                                    'direction','align',
                                                                    'link'
                                                                ]}
                                                                theme="snow"
                                                                value={value}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                ref={ref} />
                                                        )}
                                                    />
                                                    <Box sx={{minHeight: '25px'}}>
                                                        {errors.contrato_descricao&&<FormHelperText sx={{marginLeft: '0px'}}>{errors.contrato_descricao?.message}</FormHelperText>}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <Button variant='contained'>Enviar</Button>
                                            </Box>
                                        </>
                                    )
                                }
                            </Box>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default FeedbackButton;
