import { configureStore } from '@reduxjs/toolkit';

import customizationReducer from './customizationReducer';
import snackbarReducer from './SnackBar/reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
    reducer:{
        customization: customizationReducer,
        snackbar: snackbarReducer
    }
})
//const persister = 'Free';
