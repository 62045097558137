const initialState = {
    open: false,
    message: '',
    status: 'success'
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_SNACKBAR':
            return {
                ...state,
                open: true,
                ...action.payload
            };
            case 'HIDE_SNACKBAR':
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
};

export default snackbarReducer;