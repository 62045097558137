import {createTheme} from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import {ThemeConfigContext} from 'contexts/themeConfig';
import {useContext} from 'react';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const Theme=(customization) => {
  const {themeConfig}=useContext(ThemeConfigContext);
  const pathname=window.location.pathname;
  const config = {
    colors: JSON.parse(localStorage.getItem('colors')),
  }
  let color=colors;

  if(pathname.includes('dashboard/')) {
    color=colors;
    color.backgroundDefault="rgb(255, 255, 255)";
    color.primaryLight="rgb(255, 255, 255)";
    color.darkTextPrimary="#fff";
    color.menuSelectedBack='#612466';
    color.buttonIcon='#612466';
    color.heading="rgb(6, 12, 17))";
    color.resoOrange="#efecef";
    color.resoHoverOrange='#612466';
    color.tableContraste="rgb(233, 233, 233, 0.7)"
    color.tableBorder='#00000047';
    color.tableHeader='#612466';
    color.button='#612466';
    color.buttonHover='#e82276';
    color.primaryPinePr='#612466';
    color.secondaryPinePr='#e82276';
    color.tertiaryPinePr='#00a29b';
    color.quaternaryPinePr='#e0e329';
    customization={fontFamily: 'arboria, sans-serif'};
  } else {
    switch(themeConfig) {
      case 'login':
        color=colors;
        color.primaryLightDisabled='rgb(233, 233, 233, 0.3)';
        color.backgroundDefault="rgb(255, 255, 255)";
        color.primaryLight="rgb(255, 255, 255)";
        color.darkTextPrimary="#fff";
        color.buttonIcon='#fff';
        color.menuSelectedBack='#612466';
        color.heading="rgb(6, 12, 17))";
        color.resoOrange="#efecef";
        color.resoHoverOrange='rgb(237, 140, 59, 1)';
        color.tableContraste="rgb(233, 233, 233, 0.7)"
        color.tableBorder='#00000047';
        color.tableHeader='rgb(237, 140, 59, 1)';
        color.button='#612466';
        color.buttonHover='#e82276';
        color.textColor = '#000000';
        break;
      case 'light':
        customization={fontFamily: "Outfit, sans-serif"};
        color=colors;
        color.backgroundDefault=config.colors.light.backgroundPrimary;
        color.primaryLight='rgb(233, 233, 233)';
        color.primaryLightDisabled='rgb(233, 233, 233, 0.3)';
        color.darkTextPrimary=config.colors.light.textPrimary;
        color.buttonIcon=config.colors.light.textPrimary;
        color.menuSelectedBack='rgb(237, 140, 59, 1)';
        color.heading=config.colors.light.textPrimary;
        color.resoOrange=config.colors.light.backgroundSecondary;
        color.resoHoverOrange='rgb(237, 140, 59, 1)';
        color.tableContraste="rgb(233, 233, 233, 0.7)"
        color.tableBorder='#000000';
        color.tableHeader='rgb(237, 140, 59, 1)';
        color.button='rgb(237, 140, 59, 1)';
        color.buttonHover='#b3682a';
        color.buttonText='black';
        color.textColor = '#000000';
        color.titleCard = '#2d2d2d';
        break;
      case 'dark':
        color=colors;
        color.backgroundDefault=config.colors.dark.backgroundPrimary;
        color.primaryLight=config.colors.dark.backgroundSecondary;
        color.primaryLightDisabled='rgba(25, 36, 59, 0.2)';
        color.darkTextPrimary=config.colors.dark.textPrimary;
        color.buttonIcon=config.colors.dark.textPrimary;
        color.menuSelectedBack=config.colors.dark.menuSelected;
        color.heading=config.colors.dark.textPrimary;
        color.resoOrange=config.colors.dark.backgroundSecondary;
        color.resoHoverOrange=config.colors.dark.hoverButton;
        color.tableContraste=config.colors.dark.tableColor;
        color.tableBorder=config.colors.dark.tableBorder;
        color.tableHeader=config.colors.dark.backgroundSecondary;
        color.button=config.colors.dark.menuSelected;
        color.buttonHover=config.colors.dark.backgroundSecondary;
        color.textColor = '#fff';
        break;
      default:
        color=colors;
        color.primaryLightDisabled='rgb(233, 233, 233, 0.3)';
        color.backgroundDefault="rgb(255, 255, 255)";
        color.primaryLight="rgb(255, 255, 255)";
        color.darkTextPrimary="#fff";
        color.buttonIcon='#fff';
        color.menuSelectedBack='#612466';
        color.heading="rgb(6, 12, 17))";
        color.resoOrange="#efecef";
        color.resoHoverOrange='rgb(237, 140, 59, 1)';
        color.tableContraste="rgb(233, 233, 233, 0.7)"
        color.tableBorder='#00000047';
        color.tableHeader='rgb(237, 140, 59, 1)';
        color.button='#612466';
        color.buttonHover='#e82276';
        color.textColor = '#000000';
    }
  }

  const themeOption={
    colors: color,
    heading: color.heading,
    paper: color.paper,
    backgroundDefault: color.backgroundDefault,
    background: color.backgroundPage,
    darkTextPrimary: color.darkTextPrimary,
    darkTextSecondary: color.grey500,
    textDark: color.darkTextPrimary,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.menuSelectedBack,
    button: color.button,
    buttonHover: color.buttonHover,
    buttonText: color.buttonText,
    buttonIcon: color.buttonIcon,
    primaryLightDisabled: color.primaryLightDisabled,
    primaryPinePr: color.primaryPinePr,
    secondaryPinePr: color.secondaryPinePr,
    tertiaryPinePr: color.tertiaryPinePr,
    quaternaryPinePr: color.quaternaryPinePr,
    textColor: color.textColor,
    titleCard: color.titleCard,
    divider: color.grey200,
    customization,
  };

  const themeOptions={
    direction: 'ltr',
    palette: themePalette(themeOption),
    // palette: {
    //   primary: {
    //     main: '#ff9100'
    //   },
    //   secondary: {
    //     main: '#651fff'
    //   },
    //   reso: {
    //     orange: '#651fff',
    //     hoverOrange: '#651fff'
    //   },
    //   table: {
    //     tableContraste: '#651fff',
    //   }
    // },
    button: color.button,
    buttonHover: color.buttonHover,
    primaryPinePr: color.primaryPinePr,
    secondaryPinePr: color.secondaryPinePr,
    tertiaryPinePr: color.tertiaryPinePr,
    quaternaryPinePr: color.quaternaryPinePr,
    primaryLightDisabled: color.primaryLightDisabled,
    textColor: color.textColor,
    titleCard: color.titleCard,
    buttonText: color.buttonText,
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px'
        }
      }
    },
    typography: themeTypography(themeOption)
  };

  const themes=createTheme(themeOptions);
  themes.components=componentStyleOverrides(themeOption);

  return themes;
};

export default Theme;
