import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar,Box,ButtonBase} from '@mui/material';
// project imports
import LogoSection from '../LogoSection';

// assets
import {IconMenu2} from '@tabler/icons';
import SearchSection from './SearchSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header=({handleLeftDrawerToggle}) => {
  const theme=useTheme();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 260,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            // width: 'auto',
            bgcolor: 'inherit !important',
          },
          [theme.breakpoints.up('md')]: {
            // boxShadow: leftDrawerOpened? '9px 13px 15px -3px rgba(0,0,0,0.1)':'',
          },
          alignItems: 'center',
          // bgcolor: leftDrawerOpened? theme.palette.background.default:'inherit',
          p: '16px'
        }}
      >
        <ButtonBase sx={{borderRadius: '50%',overflow: 'hidden'}}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: `${theme.palette.reso.orange}`,
              bgcolor: 'white !important',
              borderRadius: '50%',
              ':hover': {bgcolor: '#c6c7e2 !important'},
              color: 'white',
              '&[aria-controls="menu-list-grow"],&:hover': {
                backgroundColor: '#2196f3',
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 color='gray' stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
        <Box component="span" sx={{display: {xs: 'none',md: 'block'},ml: '20px'}}>
          <LogoSection />
        </Box>
      </Box>
      <SearchSection />
    </>
  );
};

Header.propTypes={
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
