import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import React,{createContext,useState,useContext} from 'react';
import {useEffect} from 'react';

export const DateFilterConfigContext=createContext();

export const useDateFilterConfig=() => useContext(DateFilterConfigContext);

export const DateFilterConfigProvider=({children}) => {
  const [DateFilterConfig,setDateFilterConfig]=useState({
    startDate: dayjs('01-06-2024','DD-MM-YYYY'),
    endDate: dayjs('30-09-2024','DD-MM-YYYY'),
  });

  useEffect(() => {
    const startDate=localStorage.getItem('startDate');
    const endDate=localStorage.getItem('endDate');
    if(startDate&&endDate) {
      setDateFilterConfig({startDate: dayjs(JSON.parse(startDate)),endDate: dayjs(JSON.parse(endDate))});
    }
  },[]);

  return (
    <DateFilterConfigContext.Provider value={{
      DateFilterConfig,setDateFilterConfig,
    }}>
      {children}
    </DateFilterConfigContext.Provider>
  );
};
