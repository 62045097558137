// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import {useContext} from 'react';
import {ConfigMenuContext} from 'contexts/ConfigMenu';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const menu = useContext(ConfigMenuContext);

  if (menu.loading) return null;

  const navItems = menu.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
