import {useDispatch,useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {useLocation} from 'react-router';

// material-ui
import {styled,useTheme} from '@mui/material/styles';
import {AppBar,Box,CssBaseline,GlobalStyles,Toolbar,useMediaQuery} from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import {drawerWidth} from 'store/constant';
import {SET_MENU} from 'store/actions';

// assets
import {IconChevronRight} from '@tabler/icons';
import GlobalSnackbar from 'components/GlobalSnackbar';
import {useRef} from 'react';
import {AttachFile, DoneOutline, FilePresent, LocalActivity, Psychology} from '@mui/icons-material';

// styles
const Main=styled('main',{shouldForwardProp: (prop) => prop!=='open'})(({theme,open}) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }
      :{
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open? 0:-(drawerWidth),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayoutDash=() => {
  const theme=useTheme();
  const pathname = useLocation().pathname;
  const matchDownMd=useMediaQuery(theme.breakpoints.down('md'));
  const tableRef=useRef(null);
  // Handle left drawer
  const leftDrawerOpened=useSelector((state) => state.customization.opened);
  const dispatch=useDispatch();
  const handleLeftDrawerToggle=() => {
    dispatch({type: SET_MENU,opened: !leftDrawerOpened});
  };
  const menu={
    items:[{
      id: 'home',
      title: 'Dashboard', 
      type: 'group',
      children: [{
        id: 'dashboard',
        title: 'Planejamento',
        type: 'item',
        url: `/dashboard/${pathname.split('/')[2]}/planejamento`,
        icon: <Psychology />
      },{
        id: 'okrs',
        title: 'OKRs',
        type: 'item',
        url: `/dashboard/${pathname.split('/')[2]}/okrs`,
        icon: <DoneOutline />
      }, {
        id: 'clipping',
        title: 'Clipping Geral',
        type: 'item',
        url: `/dashboard/${pathname.split('/')[2]}/clipping_geral`,
        icon: <AttachFile />
      }, {
        id: 'clipping_detalhes',
        title: 'Clipping Detalhes',
        type: 'item',
        url: `/dashboard/${pathname.split('/')[2]}/clipping_detalhes`,
        icon: <FilePresent />
      }, {
        id: 'atividades',
        title: 'Atividades PinePr',
        type: 'item',
        url: `/dashboard/${pathname.split('/')[2]}/atividades_pinePr`,
        icon: <LocalActivity />
      }]
    }]
  }

  return (
    <Box id='root-mui' sx={{display: 'flex',backgroundColor: theme.palette.primary.light}} ref={tableRef}>
      <GlobalSnackbar />
      <GlobalStyles styles={{
                '.MuiChartsTooltip-valueCell': {
                    color: 'black !important',
                },
                '.MuiChartsTooltip-cell': {
                    color: 'black !important',
                },
            }} />
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          padding: '0px !important',
          bgcolor: '#eef2f6',
          transition: leftDrawerOpened? theme.transitions.create('width'):'none',
        }}
      >
        <Toolbar sx={{p: '0 !important',pr: '24px !important',backgroundColor: theme.palette.background.default}}>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} tableRef={tableRef} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd? leftDrawerOpened:!leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened} >
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={menu} icon title rightAlign />
        <Outlet />
      </Main>
      <Customization />
    </Box>
  );
};

export default MainLayoutDash;
