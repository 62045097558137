import React,{createContext,useState,useContext} from 'react';
import {useConfigMenu} from './ConfigMenu';
import {useEffect} from 'react';
import {useLocation} from 'react-router';

export const ThemeConfigContext=createContext();

export const useThemeConfig=() => useContext(ThemeConfigContext);

export const ThemeConfigProvider=({children}) => {
  const config=useConfigMenu();
  const {pathname}=useLocation();
  const [themeConfig,setThemeConfig]=useState('default');

  useEffect(() => {
    if (pathname === '/' || pathname.includes('/dashboard/')) {
      return;
    } else if (pathname.includes('login')) {
      localStorage.setItem('tema', JSON.parse(localStorage.getItem('default')));
    } else if (localStorage.getItem('tema') ?? false) {
      setThemeConfig(localStorage.getItem('tema'));
    }
  }, []);

  if (config.user && !pathname.includes('login')) {
    if (themeConfig !== config.user.color) {
      setThemeConfig(config.user.color);
    }
  }

  return (
    <ThemeConfigContext.Provider value={{
      themeConfig,setThemeConfig,
    }}>
      {children}
    </ThemeConfigContext.Provider>
  );
};
