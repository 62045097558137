import React from 'react';
import PropTypes from "prop-types";
import {CardHeader, Typography} from "@mui/material";
import Div from 'ui-component/layouts/components/Div';

const getColorObject=(color) => {

    if(typeof color==="string") {
        return {
            type: "color",
            value: color
        }
    }

    if(color.length===1) {
        return {
            type: "color",
            value: color
        }
    }

    return {
        type: "gradient",
        value: color
    };
};

const getBgColorStyle=(colors,gradientDir) => {
    const colorObject=getColorObject(colors);
    if(!colorObject)
        return null;

    if(colorObject.type==="color") {
        return {backgroundColor: colorObject.value};
    }
    else if(colorObject.type==="gradient") {
        if(gradientDir)
            return {backgroundImage: `linear-gradient(${gradientDir}, ${colorObject.value.join()})`}
        return {backgroundImage: `linear-gradient(${colorObject.value.join()})`}
    }
};


const getBgImageStyle=(imgSrc) => {
    return {
        background: `url(${imgSrc}) no-repeat center`,
        backgroundSize: 'cover'
    }
};


const renderTitle = (title) => {
    if(typeof title === "string") {
        return <Typography component={"h4"} color={"#fff"}>{title}</Typography>;
    }
    return title;
};

const JumboContent = (
    {
        avatar, title, subheader, action,
        bgColor, bgImage,
        sx, children
    }) => {

    const bgStyle = React.useMemo(() => {
        if (bgImage) {
            return getBgImageStyle(bgImage);
        } else if (bgColor) {
            return getBgColorStyle(bgColor);
        }
        return {}
    }, [bgColor, bgImage]);

    return (
        <Div sx={{...sx, position: "relative", ...bgStyle}}>
            {
                (avatar || title || action) &&
                <CardHeader
                    avatar={avatar}
                    title={renderTitle(title)}
                    subheader={subheader}
                    action={action}
                    sx={{
                        position: "relative",
                        zIndex: 3
                    }}
                />
            }
            <Div sx={{zIndex: 2, position: "relative"}}>
                {children}
            </Div>
        </Div>
    );
};

JumboContent.propTypes = {
    avatar: PropTypes.node,
    title: PropTypes.node,
    subheader: PropTypes.node,
    action: PropTypes.node,
    bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    bgImage: PropTypes.string,
    backdropColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    backdropOpacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backdrop: PropTypes.bool,
    sx: PropTypes.object
};

export default JumboContent;
