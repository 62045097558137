import axios from 'axios';

// Cria a instância personalizada do Axios
const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'urlCli': process.env.REACT_APP_ORIGIN || window.location.host,
    'rota': window.location.pathname.replace('/', ''),
  },
});

// Interceptador de requisição para verificar se deve adicionar o token
axiosConfig.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');

    // Verifica se a flag 'skipAuth' está definida. Se estiver, não adiciona o token
    if (!config.skipAuth && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosConfig;
