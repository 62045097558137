import AddIcon from '@mui/icons-material/Add';
import { TableHead, TableRow, TableCell, IconButton, Tooltip, useTheme } from '@mui/material';

export default function TableHeadComponent(props) {
  const { columns, moreData, actionAdd, border } = props;
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow>
        {moreData && (
          <TableCell width={70}>
            <Tooltip title="Adicionar mais">
              <IconButton aria-label="add user" sx={{ ...theme.typography.buttonListAdd }} onClick={() => actionAdd()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell key={index} align={column.align} style={{ fontWeight: 600 }} sx={{borderLeft: border ? '1px solid #85858547 !important' : ''}}>
            {column.label}
          </TableCell>
        ))}
        
      </TableRow>
    </TableHead>
  );
}
