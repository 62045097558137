// ==============================|| PROFILE MENU ||============================== //

import {EditOutlined,Logout,PersonOutline,RepeatOutlined} from "@mui/icons-material";
import {Avatar,Divider,IconButton,List,ListItemButton,ListItemIcon,ListItemText,Popover,Tooltip,Typography} from "@mui/material";
import axiosConfig from "config/AxiosConfig";
import {ConfigMenuContext} from "contexts/ConfigMenu";
import {useContext} from "react";
import {useCallback} from "react";
import {useState} from "react";
import {useNavigate} from "react-router";
import Div from "ui-component/layouts/components/Div";

const ProfileSection=({isAdmin}) => {
  const [anchorEl,setAnchorEl]=useState(null);
  const isOpen=Boolean(anchorEl);
  const navigate=useNavigate();
  const {user}=useContext(ConfigMenuContext);

  const onLogout=async () => {
    try {
      axiosConfig.post(`/api/logout`,{})
      navigate('/login')
      localStorage.setItem('response',null);
      localStorage.setItem('user',null);
    } catch(e) {
      console.log(e);
      navigate('/login')
      localStorage.setItem('response',null);
      localStorage.setItem('user',null);
    }
  };

  const handleClick=useCallback((event) => {
    setAnchorEl(event.currentTarget);
  },[]);

  const handleClose=useCallback(() => {
    setAnchorEl(null);
  },[]);

  return (
    <>
      <Tooltip title="Configurações do Usuário">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open? 'account-menu':undefined}
          aria-haspopup="true"
          aria-expanded={open? 'true':undefined}
          sx={{
            ':hover': {bgcolor: '#c6c7e2 !important'}
          }}
        >
          <Avatar sx={{
            width: 32,height: 32, color:'gray !important' ,bgcolor: 'white !important',
            ':hover': {bgcolor: '#c6c7e2 !important'}
          }}>{user.name[0]}</Avatar>
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          mt: 2,
          mr: 6
        }}
      >
        <>
          <Div sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: theme => theme.spacing(2.5),
          }}>
            <Avatar src={user.name[0]} alt={user.name[0]} sx={{width: 60,height: 60,mb: 2}} />
            <Typography variant={"h5"}>{user.name}</Typography>
            <Typography variant={"body1"} color="text.secondary">{user.email}</Typography>
          </Div>
          <Divider sx={{marginBottom: '8px'}} />
          <nav>
            <List disablePadding sx={{pb: 1}}>
              {
                isAdmin==='Y'&&(
                  <>
                    <ListItemButton>
                      <ListItemIcon sx={{minWidth: 36}}>
                        <PersonOutline />
                      </ListItemIcon>
                      <ListItemText primary="Perfil" sx={{my: 0}} />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon sx={{minWidth: 36}}>
                        <EditOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Editar Perfil" sx={{my: 0}} />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon sx={{minWidth: 36}}>
                        <RepeatOutlined />
                      </ListItemIcon>
                      <ListItemText onClick={() => navigate("/dashboard")} primary="Trocar Usuário"
                        sx={{my: 0}} />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon sx={{minWidth: 36}}>
                        <RepeatOutlined />
                      </ListItemIcon>
                      <ListItemText onClick={() => navigate("/dashboard")} primary="Trocar Cliente"
                        sx={{my: 0}} />
                    </ListItemButton>
                  </>
                )
              }
              <ListItemButton onClick={onLogout}>
                <ListItemIcon sx={{minWidth: 36}}>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{my: 0}} />
              </ListItemButton>
            </List>
          </nav>
        </>
      </Popover>
    </>
  );
};

export default ProfileSection;
