import Highcharts from 'highcharts/highstock';
import mapDataBrazil from '@highcharts/map-collection/countries/br/br-all.geo.json';
import MapChart from './components/MapChart';
import Div from 'ui-component/layouts/components/Div';
import {Box,Modal,Typography,useMediaQuery,useTheme} from '@mui/material';
import {useEffect} from 'react';
import {useState} from 'react';
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);
// import {Chart} from "react-google-charts";
import {useLocation} from 'react-router';
import {useDateFilterConfig} from 'contexts/DateFilterConfig';
import Carousel from 'react-material-ui-carousel';
import CanvasJSReact from '@canvasjs/react-charts';
import {AgCharts} from 'ag-charts-react';
import {useDispatch} from 'react-redux';
import axiosConfig from 'config/AxiosConfig';
import {useSelector} from 'react-redux';

var CanvasJSChart=CanvasJSReact.CanvasJSChart;

const labelsMap={
    'São Paulo': 'br-sp',
    'Minas Gerais': 'br-mg',
    'Rio de Janeiro': 'br-rj',
    'Bahia': 'br-ba',
    'Paraná': 'br-pr',
    'Rio Grande do Sul': 'br-rs',
    'Pernambuco': 'br-pe',
    'Ceará': 'br-ce',
    'Pará': 'br-pa',
    'Santa Catarina': 'br-sc',
    'Maranhão': 'br-ma',
    'Goiás': 'br-go',
    'Amazonas': 'br-am',
    'Espírito Santo': 'br-es',
    'Paraíba': 'br-pb',
    'Rio Grande do Norte': 'br-rn',
    'Mato Grosso': 'br-mt',
    'Alagoas': 'br-al',
    'Piauí': 'br-pi',
    'Distrito Federal': 'br-df',
    'Mato Grosso do Sul': 'br-ms',
    'Sergipe': 'br-se',
    'Rondônia': 'br-ro',
    'Tocantins': 'br-to',
    'Acre': 'br-ac',
    'Amapá': 'br-ap',
    'Roraima': 'br-rr'
}

const ClippingDetalhes=() => {
    const lessThan1024=useMediaQuery('(max-width:1030px)');
    const lessThan1368=useMediaQuery('(max-width:1370px)');
    const lessThan1536=useMediaQuery('(max-width:1536px)');
    const lessThan1920=useMediaQuery('(max-width:1925px)');
    const {pathname}=useLocation();
    const dispatch=useDispatch();
    const theme=useTheme();
    const [origem,setOrigem]=useState([]);
    const [estado,setEstado]=useState([]);
    const [portaVoz,setPortaVoz]=useState([]);
    const [assuntos,setAssuntos]=useState([]);
    const [temas,setTemas]=useState([]);
    const [loading,setLoading]=useState(true);
    const {DateFilterConfig}=useDateFilterConfig();
    const md=useMediaQuery(theme.breakpoints.up('md'));
    const isMobile=useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet=useMediaQuery(theme.breakpoints.down('md'));
    const chunkSize=isTablet? (isMobile? 1:2):3;
    const [currentIndex,setCurrentIndex]=useState(0);
    const menuOpened=useSelector((state) => state.customization.opened);

    
    useEffect(() => {
        dispatch({type: '@customization/SET_MENU', opened: false});
    }, [dispatch]);

    const handleResize=() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        },100); // Ajuste o tempo conforme necessário
    };

    useEffect(() => {
        handleResize();
    },[currentIndex, menuOpened]); // Chama handleResize sempre que currentIndex mudar

    function groupIntoChunks(array,chunkSize) {
        const output=[];
        let currentChunk=[];

        array.forEach((item,index) => {
            currentChunk.push(item);

            if((index+1)%chunkSize===0||index===array.length-1) {
                output.push(currentChunk);
                currentChunk=[];
            }
        });

        return output;
    }

    useEffect(() => {
        fetchClipping();
    },[
        DateFilterConfig,
        DateFilterConfig.startDate,
        DateFilterConfig.endDate,
    ]);

    const width=() => {
        if(lessThan1024) {
            return '100%';
        } else if(lessThan1368) {
            return '47%';
        } else if(lessThan1536) {
            return '47%';
        } else if(lessThan1920) {
            return '47%';
        }
    }

    useEffect(() => {
        fetchClipping();
    },[]);

    const fetchClipping=async () => {
        setLoading(true);
        const data_inicial=JSON.stringify(DateFilterConfig.startDate).split('T')[0].replace('"','');
        const data_final=JSON.stringify(DateFilterConfig.endDate).split('T')[0].replace('"','');
        try {
            const responseClipping=await axiosConfig.get(`/pinepr/graficos_clipping_detalhes/listar?where[contratos_id]=1&whereData[data][entre]=${data_inicial},${data_final}`, {
                skipAuth: true,
            });
            const data=responseClipping.data.data.objetos[0];
            const origem=Object.keys(data.origem).map((item) => ({label: item,value: data.origem[item]}));
            const estado=Object.keys(data.estado).map((item) => ({label: item,value: data.estado[item]}));
            const portaVoz=Object.keys(data.pinepr_portavoz).map((item) => ({label: item,value: data.pinepr_portavoz[item]}));
            const assuntos=Object.keys(data.assunto).map((item) => ({asset: item,amount: data.assunto[item]}));
            const temas=Object.keys(data.tema).map((item) => ({asset: item,amount: data.tema[item]}));
            setOrigem(origem);
            setEstado(estado);
            setPortaVoz(portaVoz);
            setAssuntos(assuntos);
            setTemas(temas);
        } catch(error) {
            console.error('Clipping fetch error: ',error);
        } finally {
            setLoading(false);
        }
    }

    if(loading) return (
        <Modal open={loading}>
            <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
                <div className="loader"></div>
            </Box>
        </Modal>
    );

    return (
        <Div sx={{padding: 2,gap: 4}}>
            <Div>
                <Typography variant="h2" color='black' gutterBottom>
                    Clipping Detalhes - {pathname.split('/')[2].toUpperCase()}
                </Typography>
            </Div>
            {
                !md? (
                    <Carousel navButtonsAlwaysInvisible={true} sx={{width: '100%',marginTop: '40px'}} onChange={(index) => setCurrentIndex(index)} >
                        {groupIntoChunks(['estadoMap','estadoBar','origem','portavoz','assunto','tema'],chunkSize).map((group,groupIndex) => (
                            <Div key={groupIndex} sx={{display: 'flex',gap: 1,width: '100%'}}>
                                {
                                    group.map((item,index) => {
                                        if(item==='estadoMap') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    <MapChart highcharts={Highcharts} options={{
                                                        chart: {
                                                            map: 'countries/br/br-all'
                                                        },
                                                        title: {
                                                            text: 'Mapa do Brasil',
                                                            style: {
                                                                fontSize: '26px',
                                                                fontWeight: 'bold'
                                                            }
                                                        },
                                                        mapNavigation: {
                                                            enabled: true,
                                                            buttonOptions: {
                                                                verticalAlign: 'bottom'
                                                            }
                                                        },
                                                        colorAxis: {
                                                            min: 0,
                                                            stops: [
                                                                [0,'#EFEFFF'],
                                                                [0.5,'#4444FF'],
                                                                [1,'#000022']
                                                            ],
                                                        },
                                                        series: [{
                                                            data: [
                                                                ...estado.map((item) => [labelsMap[item.label],item.value])
                                                            ],
                                                            mapData: mapDataBrazil,
                                                            joinBy: 'hc-key',
                                                            name: 'Series',
                                                            states: {
                                                                hover: {
                                                                    color: '#BADA55'
                                                                }
                                                            },
                                                        }]
                                                    }
                                                    } />
                                                </Div>
                                            )
                                        } else if(item==='estadoBar') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="Bar"
                                                        data={[
                                                            ['Estado','Quantidade'],
                                                            ...estado.map((item) => [item.label,item.value])
                                                        ]}
                                                        options={{
                                                            title: 'Estados',
                                                            bars: "horizontal",
                                                            legend: {position: 'none'},
                                                            colors: [theme.secondaryPinePr]
                                                        }}
                                                    /> */}
                                                    <CanvasJSChart options={{
                                                        animationEnabled: true,
                                                        title: {
                                                            text: "Estados",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bold',
                                                            fontSize: 26,
                                                            color: 'black',
                                                        },
                                                        data: [{
                                                            type: "bar",
                                                            dataPoints: [
                                                                ...estado.map((item) => ({label: item.label,y: item.value}))
                                                            ]
                                                        }]
                                                    }} />
                                                </Div>
                                            )
                                        } else if(item==='origem') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="Bar"
                                                        data={[['Origem','Quantidade'],...origem.map((item) => [item.label,item.value])]}
                                                        options={{
                                                            title: 'Origem',
                                                            legend: {position: 'none'},
                                                            colors: [theme.primaryPinePr]
                                                        }}
                                                    /> */}
                                                    <CanvasJSChart options={{
                                                        animationEnabled: true,
                                                        title: {
                                                            text: "Origem",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bold',
                                                            fontSize: 26,
                                                            color: 'black',
                                                        },
                                                        data: [{
                                                            type: "column",
                                                            dataPoints: [
                                                                ...origem.map((item) => ({label: item.label,y: item.value}))
                                                            ]
                                                        }]
                                                    }} />
                                                </Div>
                                            )
                                        } else if(item==='portavoz') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="Bar"
                                                        data={[
                                                            ['Porta-Voz','Quantidade'],
                                                            ...portaVoz.map((item) => [item.label,item.value])
                                                        ]}
                                                        options={{
                                                            title: 'Porta-Voz',
                                                            legend: {position: 'none'},
                                                            colors: [theme.tertiaryPinePr]
                                                        }}
                                                    /> */}
                                                    <CanvasJSChart options={{
                                                        animationEnabled: true,
                                                        title: {
                                                            text: "Porta-Voz",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bold',
                                                            fontSize: 26,
                                                            color: 'black',
                                                        },
                                                        data: [{
                                                            type: "column",
                                                            dataPoints: [
                                                                ...portaVoz.map((item) => ({label: item.label,y: item.value}))
                                                            ]
                                                        }]
                                                    }} />
                                                </Div>
                                            )
                                        } else if(item==='assunto') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="PieChart"
                                                        data={[
                                                            ['Assunto','Quantidade'],
                                                            ...assuntos.map((item) => [item.label,item.value])
                                                        ]}
                                                        options={{
                                                            title: 'Assuntos',
                                                        }}
                                                    /> */}
                                                    {/* <CanvasJSChart options={{
                                                        animationEnabled: true,
                                                        title: {
                                                            text: "Assuntos"
                                                        },
                                                        data: [{
                                                            type: "pie",
                                                            dataPoints: [
                                                                ...assuntos.map((item) => ({label: item.label,y: item.value}))
                                                            ]
                                                        }]
                                                    }} /> */}
                                                    <AgCharts style={{height: '400px'}} options={{
                                                        data: assuntos,
                                                        title: {
                                                            text: "Assuntos",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bolder',
                                                            fontSize: 26,
                                                            fontColor: 'black',
                                                            borderColor: 'black',
                                                        },
                                                        series: [
                                                            {
                                                                type: "pie",
                                                                angleKey: "amount",
                                                                legendItemKey: "asset",
                                                            },
                                                        ],
                                                    }} />
                                                </Div>
                                            )
                                        } else if(item==='tema') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="PieChart"
                                                        data={[
                                                            ['Tema','Quantidade'],
                                                            ...temas.map((item) => [item.label,item.value])
                                                        ]}
                                                        options={{
                                                            title: 'Temas',
                                                        }}
                                                    /> */}
                                                    {/* <CanvasJSChart options={{
                                                        animationEnabled: true,
                                                        title: {
                                                            text: "Temas"
                                                        },
                                                        data: [{
                                                            type: "pie",
                                                            dataPoints: [
                                                                ...temas.map((item) => ({label: item.label,y: item.value}))
                                                            ]
                                                        }]
                                                    }} /> */}
                                                    <AgCharts style={{height: '400px'}} options={{
                                                        data: temas,
                                                        title: {
                                                            text: "Temas",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bolder',
                                                            fontSize: 26,
                                                            fontColor: 'black',
                                                            borderColor: 'black',
                                                        },
                                                        series: [
                                                            {
                                                                type: "pie",
                                                                angleKey: "amount",
                                                                legendItemKey: "asset",
                                                            },
                                                        ],
                                                    }} />
                                                </Div>
                                            )
                                        }
                                    })}
                            </Div>
                        ))
                        }
                    </Carousel>
                ):(
                    <>
                        <Div sx={{display: 'flex',gap: 3,justifyContent: 'center',marginTop: '40px',flexWrap: 'wrap',width: '100%'}}>
                            <Div sx={{width: width()}}>
                                <MapChart highcharts={Highcharts} options={{
                                    chart: {
                                        map: 'countries/br/br-all'
                                    },
                                    title: {
                                        text: 'Mapa do Brasil',
                                        fontSize: 26,
                                    },
                                    mapNavigation: {
                                        enabled: true,
                                        buttonOptions: {
                                            verticalAlign: 'bottom'
                                        }
                                    },
                                    colorAxis: {
                                        min: 0,
                                        stops: [
                                            [0,'#EFEFFF'],
                                            [0.5,'#4444FF'],
                                            [1,'#000022']
                                        ],
                                    },
                                    series: [{
                                        data: [
                                            ...estado.map((item) => [labelsMap[item.label],item.value])
                                        ],
                                        mapData: mapDataBrazil,
                                        joinBy: 'hc-key',
                                        name: 'Series',
                                        states: {
                                            hover: {
                                                color: '#BADA55'
                                            }
                                        },
                                    }]
                                }
                                } />
                            </Div>
                            <Div sx={{width: width()}}>
                                {/* <Chart
                                    width={'100%'}
                                    height={'400px'}
                                    chartType="Bar"
                                    data={[
                                        ['Estado','Quantidade'],
                                        ...estado.map((item) => [item.label,item.value])
                                    ]}
                                    options={{
                                        title: 'Estados',
                                        bars: "horizontal",
                                        legend: {position: 'none'},
                                        colors: [theme.secondaryPinePr]
                                    }}
                                /> */}
                                <CanvasJSChart options={{
                                    animationEnabled: true,
                                    title: {
                                        text: "Estados",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bold',
                                        fontSize: 26,
                                        color: 'black',
                                    },
                                    data: [{
                                        type: "bar",
                                        dataPoints: [
                                            ...estado.map((item) => ({label: item.label,y: item.value}))
                                        ]
                                    }]
                                }} />
                            </Div>
                        </Div>
                        <Div sx={{display: 'flex',gap: 3,justifyContent: 'center',marginTop: '70px',flexWrap: 'wrap',width: '100%'}}>
                            <Div sx={{width: width()}}>
                                <CanvasJSChart options={{
                                    animationEnabled: true,
                                    height: 400,
                                    title: {
                                        text: "Origem",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bold',
                                        fontSize: 26,
                                        color: 'black',
                                    },
                                    data: [{
                                        type: "column",
                                        dataPoints: [
                                            ...origem.map((item) => ({label: item.label,y: item.value}))
                                        ]
                                    }]
                                }} />
                            </Div>
                            <Div sx={{width: width()}}>
                                <CanvasJSChart options={{
                                    animationEnabled: true,
                                    height: 419,
                                    title: {
                                        text: "Porta-Voz",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bold',
                                        fontSize: 26,
                                        color: 'black',
                                    },
                                    axisX: {
                                        labelAngle: 0,
                                    },
                                    data: [{
                                        type: "column",
                                        dataPoints: [
                                            ...portaVoz.map((item) => ({label: item.label,y: item.value}))
                                        ]
                                    }]
                                }} />
                            </Div>
                        </Div>
                        <Div sx={{display: 'flex',gap: 3,justifyContent: 'center',marginTop: '20px',flexWrap: 'wrap',width: '100%'}}>
                            <Div sx={{width: width()}}>
                                {/* <Chart
                                    width={'100%'}
                                    height={'400px'}
                                    chartType="PieChart"
                                    data={[
                                        ['Assunto','Quantidade'],
                                        ...assuntos.map((item) => [item.label,item.value])
                                    ]}
                                    options={{
                                        title: 'Assuntos',
                                    }}
                                /> */}
                                {/* <CanvasJSChart options={{
                                    animationEnabled: true,
                                    title: {
                                        text: "Assuntos"
                                    },
                                    data: [{
                                        type: "pie",
                                        dataPoints: [
                                            ...assuntos.map((item) => ({label: item.label,y: item.value}))
                                        ]
                                    }]
                                }} /> */}
                                <AgCharts style={{height: '400px'}} options={{
                                    data: assuntos,
                                    title: {
                                        text: "Assuntos",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bolder',
                                        fontSize: 26,
                                        fontColor: 'black',
                                        borderColor: 'black',
                                    },
                                    series: [
                                        {
                                            type: "pie",
                                            angleKey: "amount",
                                            legendItemKey: "asset",
                                        },
                                    ],
                                }} />
                            </Div>
                            <Div sx={{width: width()}}>
                                {/* <Chart
                                    width={'100%'}
                                    height={'400px'}
                                    chartType="PieChart"
                                    data={[
                                        ['Tema','Quantidade'],
                                        ...temas.map((item) => [item.label,item.value])
                                    ]}
                                    options={{
                                        title: 'Temas',
                                    }}
                                /> */}
                                {/* <CanvasJSChart options={{
                                    animationEnabled: true,
                                    title: {
                                        text: "Temas"
                                    },
                                    data: [{
                                        type: "pie",
                                        dataPoints: [
                                            ...temas.map((item) => ({label: item.label,y: item.value}))
                                        ]
                                    }]
                                }} /> */}
                                <AgCharts style={{height: '400px'}} options={{
                                    data: temas,
                                    title: {
                                        text: "Temas",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bolder',
                                        fontSize: 26,
                                        fontColor: 'black',
                                        borderColor: 'black',
                                    },
                                    series: [
                                        {
                                            type: "pie",
                                            angleKey: "amount",
                                            legendItemKey: "asset",
                                        },
                                    ],
                                }} />
                            </Div>
                        </Div>
                    </>
                )
            }
        </Div>
    );
};

export default ClippingDetalhes;