import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Grid,
  Popper,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';


// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import {useSocketConfig} from 'contexts/SocketConfig';
import Div from 'ui-component/layouts/components/Div';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const {notifications, markAsViewed}=useSocketConfig();

  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);


  return (
    <>
      <Box 
        sx={{
          ml: 2,
          mr: 2,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '50%'}}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              position: 'relative',
              borderRadius: '50%',
              bgcolor: 'white !important',
              ':hover': {bgcolor: '#c6c7e2 !important'}
          }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <Div sx={{position: 'absolute', top: '3px', right: '3px', background: '#c6c7e2',borderRadius: '50%', width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography>
                {notifications.filter((item) => item.visualizada===false).length}
              </Typography>
            </Div>
            <IconBell color='gray' stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            {/* <Paper sx={{p: 0}}> */}
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{mt: '-30px', width: 320}}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">Notificações</Typography>
                            <Chip
                              size="small"
                              label={notifications.filter((item) => item.visualizada===false).length}
                              sx={{
                                mr: '8px !important',
                                ml: 0,
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Typography onClick={() => {
                            notifications.forEach((notification) => {
                              if(notification.visualizada===false) {
                                markAsViewed({notificationId: notification.id});
                              }
                            });
                          }} component={Link} to="#" variant="subtitle2" color="primary">
                            Marcar todas como lidas
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <NotificationList notifications={notifications} markAsViewed={markAsViewed} handleClose={handleToggle} />
                    </Grid>
                  </Grid>
                  <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button size="small" disableElevation>
                      Ver todas as notificações
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            {/* </Paper> */}
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
