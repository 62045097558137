import {Box,Button,GlobalStyles,Grid,Modal,Pagination,Table,TableBody,TableCell,TableContainer,TablePagination,TableRow,TextField,Typography,keyframes,useMediaQuery,useTheme} from "@mui/material";
import Div from "../../../ui-component/layouts/components/Div";
import TableHeadComponent from "components/TableHeadComponent";
import {useEffect} from "react";
import {useState} from "react";
import {IconFilter} from "@tabler/icons";
import {useDispatch} from "react-redux";
import {showSnackbar} from "store/SnackBar/actions";
import {useLocation} from "react-router";
import {useDateFilterConfig} from "contexts/DateFilterConfig";
import Carousel from "react-material-ui-carousel";
import CanvasJSReact from '@canvasjs/react-charts';
import {useSearchParams} from "react-router-dom";
import axiosConfig from "config/AxiosConfig";
import {useSelector} from "react-redux";

var CanvasJSChart=CanvasJSReact.CanvasJSChart;

const myEffectIn=keyframes`
  0% {
    opacity: 0;
    height: 0px;
  },
  50% {
    opacity: 0;
  },
  100% {
    opacity: 1;
    height: 127px;
  }
`;

const myEffectOut=keyframes`
  0% {
    opacity: 1;
    height: 127px;
  },
  50% {
    opacity: 0;
  },
  100% {
    opacity: 0;
    height: 0px;
  }
`;

const Clipping=() => {
    const [clipping,setClipping]=useState([])
    const [filterModalOpen,setFilterModalOpen]=useState(false);
    const [openModalAnimation,setOpenModalAnimation]=useState(false);
    const [filters,setFilters]=useState({});
    const [removeFilter,setRemoveFilter]=useState(false);
    const [totalEq,setTotalEq]=useState(0);
    const dispatch=useDispatch();
    const pathname=useLocation().pathname;
    const [loading,setLoading]=useState(true);
    const [datasInsercoes,setDatasInsercoes]=useState([]);
    const {DateFilterConfig}=useDateFilterConfig();
    const theme=useTheme();
    const md=useMediaQuery(theme.breakpoints.up('md'));
    const [currentIndex,setCurrentIndex]=useState(0);
    const [page,setPage]=useState(0);
    const [rowsPerPage,setRowsPerPage]=useState(10);
    const [rowsPerPagePrev,setRowsPerPagePrev]=useState(10);
    const [pagePrev,setPagePrev]=useState(0);
    const [totalItems,setTotalItems]=useState(0);
    const [totalPages,setTotalPages]=useState(0);
    const [searchParams,setSearchParams]=useSearchParams({
        limit: 10,
        page: 0,
    });
    const limits=[10,50,100,500];
    const menuOpened=useSelector((state) => state.customization.opened);

    useEffect(() => {
        dispatch({type: '@customization/SET_MENU',opened: false});
    },[dispatch]);

    const handleResize=() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        },150); // Ajuste o tempo conforme necessário
    };

    useEffect(() => {
        handleResize();
    },[currentIndex,menuOpened]);

    useEffect(() => {
        const limit=searchParams.get('limit');
        const page=searchParams.get('page');

        if(limit) {
            limits.forEach((value) => {
                if(value===parseInt(limit)) {
                    setRowsPerPage(parseInt(limit));
                }
            });
        } else {
            setSearchParams({page: searchParams.get('page')||0,limit: 10});
        }
        if(page) {
            setPage(parseInt(page));
        } else {
            setSearchParams({page: 0,limit: searchParams.get('limit')||10});
        }
    },[searchParams]);

    useEffect(() => {
        if(page!==pagePrev||rowsPerPage!==rowsPerPagePrev||removeFilter) {
            setRemoveFilter(false);
            applyFilters();
        }
    },[page,rowsPerPage,removeFilter]);

    useEffect(() => {
        applyFilters();
    },[
        DateFilterConfig,
        DateFilterConfig.startDate,
        DateFilterConfig.endDate,
    ]);

    useEffect(() => {
        applyFilters();
    },[])

    useEffect(() => {
        if(removeFilter) {
            setRemoveFilter(false);
            applyFilters();
        }
    },[removeFilter]);

    const handleOpenFilterModal=() => {
        if(filterModalOpen) {
            setOpenModalAnimation(true);
            setTimeout(() => {
                setFilterModalOpen(!filterModalOpen);
                setOpenModalAnimation(false);
            },300);
        } else {
            setFilterModalOpen(!filterModalOpen);
        }
    };

    const applyFilters=async () => {
        setLoading(true);
        let filtrosQuery='';
        Object.keys(filters).map((key) => {
            if(filters[key]!=='') {
                filtrosQuery+=`&${key}=${filters[key]}`;
            }
        });
        const data_inicial=JSON.stringify(DateFilterConfig.startDate).split('T')[0].replace('"','');
        const data_final=JSON.stringify(DateFilterConfig.endDate).split('T')[0].replace('"','');
        try {
            const response=await axiosConfig.get(`/pinepr/clipping/listar?page=${page}&limit=${rowsPerPage}&where[contratos_id]=1${filtrosQuery}&whereData[data][entre]=${data_inicial},${data_final}`,{
                skipAuth: true,
            });
            const responseInsercoes=await axiosConfig.get(`/pinepr/graficos_insercoes/listar?where[contratos_id]=1&whereData[data][entre]=${data_inicial},${data_final}`,{
                skipAuth: true,
            });

            const datasInsercoes=[];

            let totalEqComercial=0;

            const data=responseInsercoes.data.data.objetos[0];
            Object.keys(data).map((key) => {
                if(key!=='equivalencia') {
                    Object.keys(data[key]).map((month) => {
                        const index=datasInsercoes.findIndex((item) => item.year===key&&item.month===month);
                        if(index!==-1) {
                            datasInsercoes[index].value+=data[key][month];
                        } else {
                            datasInsercoes.push({year: key,month: month,value: data[key][month]});
                        }
                    });
                } else {
                    totalEqComercial=data[key];
                }
            });

            const refactorData=response.data.data.objetos.map((item) => {
                const eq_comercial=item.eq_comercial? Number(item.eq_comercial).toLocaleString('pt-br',{style: 'currency',currency: 'BRL'}):'0';
                return {
                    data: item.data,
                    tipo_pinepr_canal: item.tipo_pinepr_canal.tipo,
                    titulo: item.titulo,
                    tipo: item.tipo_pinepr_veiculo.tipo,
                    tipo_pinepr_tema: item.tipo_pinepr_tema.tipo,
                    tipo_pinepr_origem: item.tipo_pinepr_origem.tipo,
                    tipo_pinepr_assunto: item.tipo_pinepr_assunto.tipo,
                    link_externo: item.link_externo,
                    pinepr_portavoz: item.pinepr_portavoz? item.pinepr_portavoz.portavoz:'N/A',
                    alcance: item.alcance,
                    eq_comercial: eq_comercial,
                }
            });
            const dataOrdenadasPeloMes=datasInsercoes.sort((a,b) => b.month-a.month);
            const datasOrdenadas=dataOrdenadasPeloMes.sort((a,b) => b.year-a.year);
            setTimeout(() => {
                setClipping(refactorData);
                setTotalEq(totalEqComercial);
                setDatasInsercoes(datasOrdenadas);
                setTotalItems(response.data.data.paginacao.total);
                setTotalPages(response.data.data.paginacao.lastPage);
                setPagePrev(page);
                setRowsPerPagePrev(rowsPerPage);
            },50);
        } catch(error) {
            console.log(error);
            setTimeout(() => {
                dispatch(showSnackbar(`Erro ao filtrar`,'error'));
            },50);
        } finally {
            setLoading(false);
        }
    };

    const handleClearFilters=() => {
        setFilters(
            {
                'whereR[tipo_pinepr_canal][tipo][like]': '',
                'whereR[tipo_pinepr_origem][tipo][like]': '',
                'whereR[tipo_pinepr_veiculo][tipo][like]': '',
                'whereR[tipo_pinepr_tema][tipo][like]': ''
            }
        )
        setRemoveFilter(true);
    };

    const filterModal=() => {
        return (
            <Box sx={{animation: filterModalOpen&&!openModalAnimation? `${myEffectIn} 0.3s ease-in-out`:`${myEffectOut} 0.3s ease-in-out`}}>
                <Grid container spacing={2} sx={{padding: 2,marginLeft: 0,marginTop: '0px !important',width: '100%'}}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop: `0 !important`}}>
                        <TextField
                            sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`,'[class*="MuiOutlinedInput-input"]': {color: 'black !important'}}}
                            label={'Canal'}
                            variant="outlined"
                            size="small"
                            value={filters?.['whereR[tipo_pinepr_canal][tipo][like]']}
                            onChange={(e) => setFilters({...filters,'whereR[tipo_pinepr_canal][tipo][like]': e.target.value})}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop: `0 !important`,'[class*="MuiOutlinedInput-input"]': {color: 'black !important'}}}>
                        <TextField
                            sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`}}
                            label={'Origem'}
                            variant="outlined"
                            size="small"
                            value={filters?.['whereR[tipo_pinepr_origem][tipo][like]']}
                            onChange={(e) => setFilters({...filters,'whereR[tipo_pinepr_origem][tipo][like]': e.target.value})}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop: `0 !important`,'[class*="MuiOutlinedInput-input"]': {color: 'black !important'}}}>
                        <TextField
                            sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`}}
                            label={'Tipo'}
                            variant="outlined"
                            size="small"
                            value={filters?.['whereR[tipo_pinepr_veiculo][tipo][like]']}
                            onChange={(e) => setFilters({...filters,'whereR[tipo_pinepr_veiculo][tipo][like]': e.target.value})}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop: `0 !important`,'[class*="MuiOutlinedInput-input"]': {color: 'black !important'}}}>
                        <TextField
                            sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`}}
                            label={'Tema'}
                            variant="outlined"
                            size="small"
                            value={filters?.['whereR[tipo_pinepr_tema][tipo][like]']}
                            onChange={(e) => setFilters({...filters,'whereR[tipo_pinepr_tema][tipo][like]': e.target.value})}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid sx={{gap: 2,display: 'flex'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                            variant="contained"
                            onClick={applyFilters}
                        >
                            Filtrar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleClearFilters}
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const handleChangePage=(event,newPage) => {
        setPage(newPage);
        setSearchParams({page: newPage,limit: searchParams.get('limit')});
    };

    const handleChangeRowsPerPage=(event) => {
        setSearchParams({page: 0,limit: event.target.value});
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if(loading) return (
        <Modal open={loading}>
            <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
                <div className="loader"></div>
            </Box>
        </Modal>
    );

    const columns=[
        {id: 'data',label: 'Data',minWidth: 170,align: 'center'},
        {id: 'tipo_pinepr_canal',label: 'Canal',minWidth: 170,align: 'center'},
        {id: 'titulo',label: 'Titulo',minWidth: 170,align: 'center'},
        {id: 'tipo',label: 'Tipo',minWidth: 170,align: 'center'},
        {id: 'tipo_pinepr_tema',label: 'Tema',minWidth: 170,align: 'center'},
        {id: 'tipo_pinepr_origem',label: 'Origem',minWidth: 170,align: 'center'},
        {id: 'tipo_pinepr_assunto',label: 'Assunto',minWidth: 170,align: 'center'},
        {id: 'link_externo',label: 'Link Externo',minWidth: 170,align: 'center'},
        {id: 'pinepr_portavoz',label: 'Porta-Voz',minWidth: 170,align: 'center'},
        {id: 'alcance',label: 'Alcance',minWidth: 170,align: 'center'},
        {id: 'eq_comercial',label: 'Eq. Comercial',minWidth: 170,align: 'center'},
    ]
    return (
        <Div sx={{display: 'flex',flexDirection: 'column',gap: 4,padding: 2}}>
            <GlobalStyles styles={{'.MuiList-root': {color: 'black !important'},'.MuiPaginationItem-root': {color: 'black !important'}}} />
            <Div>
                <Typography variant="h2" color='black' gutterBottom>
                    Clipping Geral - {pathname.split('/')[2].toUpperCase()}
                </Typography>
            </Div>
            {
                !md? (
                    <Carousel
                        navButtonsAlwaysInvisible={true}
                        autoPlay={false}
                        sx={{width: '100%',height: '310px'}}
                        onChange={(index) => setCurrentIndex(index)} // Atualiza o currentIndex quando o slide muda
                    >
                        {
                            ['div','chart'].map((tiersT,groupIndex) => {
                                if(tiersT==='div') return (
                                    <Div key={`${tiersT}-${groupIndex}-${currentIndex}`} sx={{display: 'flex',gap: 1,width: '100%',height: '310px'}}>
                                        <Div
                                            key={`${tiersT}-${groupIndex}-${currentIndex}`}  // Use currentIndex no key para forçar a re-renderização
                                            sx={{width: `100%`,visibility: groupIndex===currentIndex? 'visible':'hidden',display: 'flex',justifyContent: 'center',bgcolor: theme.secondaryPinePr,padding: 2,borderRadius: '5px',alignItems: 'center'}}
                                        >
                                            <Div
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 1,
                                                    height: '310px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                <Typography variant="h3" color={'white'} gutterBottom>
                                                    Equivalência Comercial Total (do período):
                                                </Typography>
                                                <Typography variant="h1" sx={{textAlign: 'center',fontSize: '2.1rem',marginTop: '35px'}} color={'white'} gutterBottom>
                                                    {totalEq.toLocaleString('pt-br',{style: 'currency',currency: 'BRL'})}
                                                </Typography>
                                            </Div>
                                        </Div>
                                    </Div>
                                )
                                return (
                                    <Div key={`${tiersT}-${groupIndex}-${currentIndex}`} sx={{display: 'flex',gap: 1,width: '100%',height: '310px'}}>
                                        <Div
                                            key={`${tiersT}-${groupIndex}-${currentIndex}`}  // Use currentIndex no key para forçar a re-renderização
                                            sx={{width: `100%`,visibility: groupIndex===currentIndex? 'visible':'hidden',display: 'flex',justifyContent: 'space-evenly',padding: 2,bgcolor: 'white',borderRadius: '5px',boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.85)'}}
                                        >
                                            {/* <Chart
                                                width={'100%'}
                                                height={'310px'}
                                                chartType="Line"
                                                data={[
                                                    [`Total de inserções (do período): ${datasInsercoes.reduce((acc,item) => acc+item.value,0)}`,'Quantidade'],
                                                    ...datasInsercoes.map((item) => [monthLabels[item.month],item.value])
                                                ]}
                                                options={{
                                                    title: 'Inserções',
                                                    bars: "horizontal",
                                                    legend: {position: 'none'},
                                                    colors: [theme.secondaryPinePr]
                                                }}
                                            /> */}
                                            <CanvasJSChart
                                                options={{
                                                    title: {
                                                        text: [`Total de inserções (do período): ${datasInsercoes.reduce((acc,item) => acc+item.value,0)}`],
                                                        fontColor: 'black',
                                                        fontSize: 26,
                                                    },
                                                    data: [
                                                        {
                                                            type: "line",
                                                            dataPoints: datasInsercoes.map((item) => {
                                                                return {
                                                                    label: item.month,
                                                                    y: item.value
                                                                }
                                                            })
                                                        }
                                                    ]
                                                }}
                                            />
                                        </Div>
                                    </Div>
                                )
                            })}
                    </Carousel>
                ):(
                    <Div sx={{display: 'flex',gap: 2}}>
                        <Div sx={{width: '50%',display: 'flex',justifyContent: 'center',bgcolor: theme.secondaryPinePr,padding: 2,borderRadius: '5px',alignItems: 'center'}}>
                            <Div sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Typography variant="h3" color={'white'} gutterBottom>
                                    Equivalência Comercial Total (do período):
                                </Typography>
                                <Typography variant="h1" sx={{textAlign: 'center',fontSize: '2.7rem',marginTop: '35px'}} color={'white'} gutterBottom>
                                    {totalEq.toLocaleString('pt-br',{style: 'currency',currency: 'BRL'})}
                                </Typography>
                            </Div>
                        </Div>
                        <Div sx={{width: '50%',display: 'flex',justifyContent: 'space-evenly',padding: 2,bgcolor: 'white',borderRadius: '5px',boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.85)'}}>
                            <Div className="chart_clipping" sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}>
                                {/* <Chart
                                    width={'100%'}
                                    height={'310px'}
                                    chartType="Line"
                                    data={[
                                        [`Total de inserções (do período): ${datasInsercoes.reduce((acc,item) => acc+item.value,0)}`,'Quantidade'],
                                        ...datasInsercoes.map((item) => [monthLabels[item.month],item.value])
                                    ]}
                                    options={{
                                        title: 'Inserções',
                                        bars: "horizontal",
                                        legend: {position: 'none'},
                                        colors: [theme.secondaryPinePr],
                                        pointSize: 5,
                                        lineWidth: 5,
                                        titleTextStyle: {color: 'black',bold: true, fontSize: 14},
                                    }}
                                /> */}
                                <CanvasJSChart
                                    options={{
                                        // width: width(),
                                        height: 400,
                                        title: {
                                            text: [`Total de inserções (do período): ${datasInsercoes.reduce((acc,item) => acc+item.value,0)}`],
                                            fontFamily: 'Arial',
                                            fontWeight: 'bold',
                                            fontSize: 26,
                                            color: 'black',
                                        },
                                        data: [
                                            {
                                                type: "line",
                                                dataPoints: datasInsercoes.map((item) => {
                                                    return {
                                                        label: item.month,
                                                        y: item.value
                                                    }
                                                })
                                            }
                                        ]
                                    }}
                                />
                            </Div>
                        </Div>
                    </Div>
                )
            }
            <Div sx={{width: '100%',display: 'flex',flexDirection: 'column',gap: 3}}>
                <Div>
                    <Button variant='outlined' startIcon={<IconFilter />} sx={{color: 'white !important'}} onClick={handleOpenFilterModal}>Filtros</Button>
                </Div>
                <Div>
                    {filterModalOpen&&filterModal()}
                </Div>
                <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                    <TablePagination
                        rowsPerPageOptions={limits}
                        component="div"
                        labelRowsPerPage={'Exibir'}
                        labelDisplayedRows={() => ''}
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{style: {display: "none"}}}
                        backIconButtonProps={{style: {display: "none"}}}
                        sx={{
                            '& .MuiSelect-select': {
                                color: `black !important`
                            },
                            '& .MuiTablePagination-selectLabel': {
                                color: 'black !important'
                            },
                            '.MuiTablePagination-menuItem': {
                                color: 'black !important'
                            },
                            'MuiList-root': {
                                color: 'black !important'
                            }
                        }}
                    />
                    <Pagination page={page} onChange={handleChangePage} count={totalPages} variant="outlined" shape="rounded" />
                </Box>
                <TableContainer sx={{maxHeight: '100%',borderRadius: 1}}>
                    <Table aria-label="customized collapsible table">
                        <TableHeadComponent columns={columns} border={true} />
                        <TableBody>
                            {clipping.map((row,index) => (
                                <TableRow key={`${row.titulo} - ${index}`}>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">
                                        {
                                            row.data.split('-').reverse().join('/')
                                        }
                                    </TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo_pinepr_canal}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">
                                        {row.titulo.length>30? `${row.titulo.substring(0,30)}...`:row.titulo}
                                    </TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo_pinepr_tema}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo_pinepr_origem}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo_pinepr_assunto}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">
                                        <a href={row.link_externo} target="_blank" rel="noreferrer">{row.link_externo.length>20? `${row.link_externo.substring(0,20)}...`:row.link_externo}</a>
                                    </TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.pinepr_portavoz}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.alcance}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.eq_comercial}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Div>
        </Div>
    )
};

export default Clipping;