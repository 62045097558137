import React from 'react';
import {Grid, ListItemText, Tooltip, Typography, lighten, useMediaQuery} from "@mui/material";
import Div from 'ui-component/layouts/components/Div';
import {Bar, BarChart, ResponsiveContainer, XAxis} from 'recharts';
import CardQuick from './CardQuick';

const revenueHistory = [
    {month: 'Jan', profit: 0, revenue: 400},
    {month: 'Feb', profit: 150, revenue: 350},
    {month: 'Mar', profit: 100, revenue: 0},
    {month: 'Apr', profit: 300, revenue: 50},
    {month: 'May', profit: 100, revenue: 300},
    {month: 'Jun', profit: 100, revenue: 450},
    {month: 'Jul', profit: 150, revenue: 450},
    {month: 'Aug', profit: 150, revenue: 20},
    {month: 'Sep', profit: 200, revenue: 50},
    {month: 'Oct', profit: 40, revenue: 400},
    {month: 'Nov', profit: 60, revenue: 50},
    {month: 'Dec', profit: 0, revenue: 400},
];

const RevenueGraph = () => {
    return (
        <ResponsiveContainer width="100%" height={45} style={{marginTop: '-15px'}}>
            <BarChart data={revenueHistory} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip
                    animationEasing={"ease-in-out"}
                    content={({active, label, payload}) => {
                        return active ? (
                            <Div key={label} sx={{color: "common.white"}}>
                                {payload.map((row, index) => {
                                    return (
                                        <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                            <div style={{
                                                color: row.color,
                                                fontSize: 8,
                                                letterSpacing: 2,
                                                textTransform: 'uppercase'
                                            }}>
                                                {row.name}
                                            </div>
                                            <div style={{
                                                color: row.color
                                            }}
                                            >{row.value} USD
                                            </div>
                                        </div>
                                    )
                                })}
                            </Div>
                        ) : null;
                    }}
                    wrapperStyle={{
                        background: 'rgba(255,255,255,0.9)',
                        borderRadius: 4,
                        padding: '5px 8px',
                        fontWeight: 500,
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                />
                <XAxis dataKey="month" hide />
                <Bar dataKey="revenue" stackId="a" fill="#e82276" barSize={6} />
                <Bar dataKey="profit" stackId="a" fill="#00a29b" barSize={6} />
            </BarChart>
        </ResponsiveContainer>
    );
};

const CardRevenue = ({value, title = 'Faturamento Anual'}) => {
    const xl = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    
    return (
        <CardQuick
            title={title}
            wrapperSx={{pt: 1}}
            sx={{color: "common.white",borderRadius: '15px',boxShadow: '#5857597d 0px 0px 1.25rem 3px', height: xl ? '175px' : '185px', display: 'flex', flexDirection: 'column'}}
            bgColor={[lighten('#612466',0.3),'#612466']}
        >
            <Grid container spacing={3.75}>
                <Grid item xs={12}>
                    <ListItemText
                        primary={<Typography variant={"h2"} mb={.5}>{value}</Typography>}
                        // secondary={<Typography variant={'body1'} color={'common.white'}>Faturamento Anual</Typography>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RevenueGraph/>
                </Grid>
            </Grid>
        </CardQuick>

    );
};
export default CardRevenue;
