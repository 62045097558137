import {useRoutes} from 'react-router-dom';

// routes
// import MainRoutes from './MainRoutes';
import MainRoutesDash from './MainRoutesDash';
import Loadable from 'ui-component/Loadable';
import {lazy} from 'react';

import LoginRoutes from './LoginRoutes';
import {useConfigMenu} from 'contexts/ConfigMenu';
import MainLayout from 'layout/MainLayout';
import renderImports from './imports';
import Actions from 'views/pages/outros/joinjs/Actions';
import axios from 'axios';
import {useState} from 'react';
import {useEffect} from 'react';
const Listar=Loadable(lazy(() => import('../layout/ListarLayout')));
const DashboardDefault=Loadable(lazy(() => import('views/dashboard/Default')));
const ControleCarros=Loadable(lazy(() => import('views/pages/outros/painel-de-controle/Painel')));
const ListarFuncionarios=Loadable(lazy(() => import('views/pages/outros/funcionarios/Funcionarios')));
// const Mapa=Loadable(lazy(() => import('views/pages/outros/mapa/Mapa')));
const ListarCargoSalario=Loadable(lazy(() => import('views/pages/outros/cargo-salario/CargoSalario')));
const ActionsPermissoes=Loadable(lazy(() => import('views/pages/configuracoes/usuarios/Permissoes')));

const MainRoutes={
  path: '/',
  element: <MainLayout />,
  children: [{
    path: '/dashboard',
    element: <DashboardDefault />
  },
    // {
    //   path: '/mapa',
    //   element: <Actions />
    // },
  ]
}

const MainRoutesManual={
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/controle-de-carros',
      element: <ControleCarros />
    },
    {
      path: '/mapa',
      element: <Actions />
    },
    {
      path: '/',
      children: [
        {
          path: 'funcionarios/listar',
          element: <ListarFuncionarios />,
        },
        {
          path: 'funcionarios/cadastrar',
          element: renderImports('funcionarios','cadastrar')
        },
        {
          path: 'funcionarios/editar/:id',
          element: renderImports('funcionarios','editar')
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'cargo-salario/listar',
          element: <ListarCargoSalario />,
        },
        {
          path: 'cargo-salario/cadastrar',
          element: renderImports('cargo-salario','cadastrar')
        },
        {
          path: 'cargo-salario/editar/:id',
          element: renderImports('cargo-salario','editar')
        }
      ]
    }
  ]
}

const ElementPreview= ({endpoint}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pagina=await axios.get(endpoint)
        setData(pagina.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  if (!data) {
    return <div>Loading...</div>
  }

  return <div className='pace-done navbar-scrolled page-scrolled menu-hide' dangerouslySetInnerHTML={{__html: data}}>
  </div>
}

const ElementRender=(chave,item,itemChildren) => {
  let columns=[];

  if(itemChildren.botoes_menu_avancados) {
    const item=itemChildren.botoes_menu_avancados[chave];

    
    if(item) {
      // const endpoint = item.endpoint.includes('http') ? item.endpoint : `${process.env.REACT_APP_BACKEND_URL}${item.endpoint}`;
      const endpoint = item.endpoint.includes('http') ? item.endpoint : `http://10.10.10.118:8000${item.endpoint}`;
      return <ElementPreview endpoint={endpoint}/>
    }
  }

  if(chave==='listar') {
    if(itemChildren?.tela_listar?.campos) {
      columns=itemChildren?.tela_listar?.campos?.map((item) => {
        let ordenacao='';
        itemChildren?.tela_listar?.ordenacao?.map((itemOrdenacao) => {
          if(itemOrdenacao.campo.includes(item.value)) {
            ordenacao=itemOrdenacao.ordenacao;
          }
        });
        if(item.icone) {
          return {
            ...item,
            type: 'collapse',
            value: '',
            label: '',
            align: 'center',
            ordenacao
          }
        }
        if(item.value&&item.value.includes('.')) {
          const splited=item.value.split('.');
          return {
            ...item,
            value: splited[splited.length-1],
            nested: item.value,
            isNested: true,
            ordenacao
          }
        } else {
          return {
            ...item,
            ordenacao
          };
        }
      });
    } else if(itemChildren?.tela_listar) {
      columns=itemChildren?.tela_listar.map((item) => {
        if(item.icone) {
          return {
            ...item,
            type: 'collapse',
            value: '',
            label: '',
            align: 'center'
          }
        }
        if(item.value&&item.value.includes('.')) {
          const splited=item.value.split('.');
          return {
            ...item,
            value: splited[splited.length-1],
            nested: item.value,
            isNested: true,
          }
        } else {
          return item;
        }
      });
    }

    if(!columns) {
      columns=[];
    }
  }
  switch(chave) {
    case 'listar':
      return <Listar key={`/${item.id}/${itemChildren.id}`} visualizarListar={false} isNewRequest columns={[...columns,{value: 'action',label: 'Opc.',align: 'center'}]} name={itemChildren.title} rotaBaseNavigate={`${item.id}/${itemChildren.id}`} rotaBase={`${item.id}/${itemChildren.id}`} />
    case 'cadastrar':
      return renderImports(itemChildren.id,'cadastrar')
    case 'editar':
      return renderImports(itemChildren.id,'editar')
    case 'permissoes':
      return <ActionsPermissoes />
    case 'visualizar':
      return renderImports(itemChildren.id,'visualizar')
    case 'responder':
      return renderImports(itemChildren.id,'responder')
  }
}

export default function ThemeRoutes() {
  const configMenu=useConfigMenu();

  const checkChave=(chave) => {
    if(chave==='editar'||chave==='permissoes'||chave==='visualizar') {
      return `${chave}/:id`
    } else {
      return chave;
    }
  };


  if(MainRoutes.children.length===1) {
    configMenu.items.map((item) => {
      item.children.map((itemChildren) => {
        const botoes_menu=itemChildren?.botoes_menu? Object.keys(itemChildren?.botoes_menu):[];
        if(botoes_menu.length>1) {
          const objData={
            path: `/${item.id}/${itemChildren.id}`,
            children: []
          }
          botoes_menu.map((chave) => {
            const objChave={
              path: checkChave(chave),
              element: ElementRender(chave,item,itemChildren)
            }
            objData.children.push(objChave);
          })
          MainRoutes.children.push(objData)
        } else {
          const lastPath=botoes_menu.length>0? `/${botoes_menu[0]}`:'';
          if(lastPath==='/listar') {
            MainRoutes.children.push({
              path: `/${item.id}/${itemChildren.id}/listar`,
              element: ElementRender('listar',item,itemChildren)
            })
          } else {
            if(item.id==='dashboard') {
              if(itemChildren.id==='resumo'||itemChildren.id==='financeiro-dashboard') {
                MainRoutes.children.push({
                  path: itemChildren.url,
                  element: renderImports(itemChildren.id)
                })
              }
              itemChildren.children?.map((itemChildrenChildren) => {
                MainRoutes.children.push({
                  path: `${itemChildrenChildren.url}`,
                  element: renderImports(itemChildrenChildren.id)
                })
              })
            } else {
              MainRoutes.children.push({
                path: `/${item.id}/${itemChildren.id}${lastPath}`,
                element: renderImports(itemChildren.id,lastPath.replace('/',''))
              })
            }
          }
        }
      })
    });
  }

  const routes=[]

  if(process.env.REACT_APP_ENVIRONMENT!='production') {
    routes.push(MainRoutesManual);
  }

  return useRoutes([LoginRoutes,MainRoutes,MainRoutesDash,routes]);
}
