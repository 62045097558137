import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, /* rectSwappingStrategy, */ sortableKeyboardCoordinates, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CancelOutlined } from '@mui/icons-material';
import { Box, Button, Divider, FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material';
import InputAutocomplete from 'components/InputAutocomplete';
import InputTextField from 'components/InputTextField';
import axiosConfig from 'config/AxiosConfig';
import ActionsLayout from 'layout/ActionsLayout';
import React, {useState, useEffect} from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { showSnackbar } from 'store/SnackBar/actions';
import { getChangedItems } from 'utils/getChangedItems';

const Actions = ({action}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [pageTitle, setPageTitle] = useState('Criar Tela');
    const [isDisabled, setIsDisabled] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [activeId, setActiveId] = useState(null);
    const [activeContainer, setActiveContainer] = useState(null);
    const [triggerRender, setTriggerRender] = useState(false);

    const dispatch = useDispatch();
    const pathname = useLocation().pathname;
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 2
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    //Para adicionar um input colocar mais um objeto aqui e fazer a logica em selectedInput
    const optionsInputs = [
        {value: 'text', label: 'Texto'},
        {value: 'textarea', label: 'Text Area'},
        {value: 'autocomplete', label: 'Select Dropdown'},
        {value: 'autocompletetag', label: 'Select Múltiplo'},
        {value: 'switch', label: 'Switch'},
    ]

    const {control, handleSubmit, watch, setValue} = useForm({
        defaultValues: {
            nome: '',
            type: '',
            rota: '',
            inputs: [],
            hasSteps: false,
            steps: [{title: '', fieldIds: [], id: 'step-0'}],
        }
    });

    /* const inputs = useWatch({ control, name: 'inputs' }) || []; */
    const inputs = watch('inputs');

    /* const steps = useWatch({ control, name: 'steps' }) || []; */
    const steps = watch('steps');

    const type = watch('type');

    const hasSteps = useWatch({ control, name: 'hasSteps' }) || false;

    //Inputs
    const { fields: fieldInputs, append: appendInputs, remove: removeInputs } = useFieldArray({
        control,
        name: 'inputs',
    });
    

    const appendOption = (option, index) => setValue(`inputs.${index}.options`, [...inputs[index].options, option]);

    const removeOption = (optionIndex, index) => setValue(`inputs.${index}.options`, [...inputs[index].options.filter((_, indexInput) => indexInput !== optionIndex)]);
    
    const nestedOptions = (index) => {

        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={'20px'}>
                    <Button 
                        type='button' 
                        onClick={() => appendOption({label: '', value: ''}, index)}
                        variant="contained" sx={{padding: '10px'}}
                        disabled={isDisabled}
                    >
                        <Typography variant='h5'>Add. Opção</Typography>
                    </Button>
                </Grid>
                {inputs[index]?.options.map((_, optionIndex) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={`options[${optionIndex}]`} 
                        display={'flex'} 
                        justifyContent={'left'}
                    >
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} marginRight={'10px'}>
                            <InputTextField 
                                name={`inputs.${index}.options[${optionIndex}].label`}
                                key={`inputs.${index}.options[${optionIndex}].label`}
                                control={control}
                                label={'Label'}
                                rules={{ required: 'Este campo é obrigatório '}}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <InputTextField 
                                name={`inputs.${index}.options[${optionIndex}].value`}
                                key={`inputs.${index}.options[${optionIndex}].value`}
                                control={control}
                                label={'Value'}
                                rules={{ required: 'Este campo é obrigatório '}}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <IconButton 
                                variant="contained" 
                                color="error"
                                key={`options[${optionIndex}].delete`}
                                sx={{color: '#f44336 !important',":hover": {'color': '#f44336 !important'}}}
                                onClick={() => {removeOption(optionIndex, index)}}
                                disabled={isDisabled}
                            >
                                <CancelOutlined color='red' sx={{width: '30px !important',height: '30px !important'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        )
    }

    const selectedInput = (type, indexInput, ref, style, attributes, listeners) => {

        if (type === 'autocomplete' || type === 'autocompletetag') {
            return (
                <Grid 
                    item xs={12} sm={12} md={4} lg={4} xl={4} key={`input${indexInput}`}
                    ref={ref} style={style}
                >
                    <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10.5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant='h4'>{`${optionsInputs.find((item) => item.value === type).label}`}</Typography>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} display={'flex'} alignItems={'center'}>
                            <Box 
                                style={{ cursor: 'grab' }}
                                {...attributes} {...listeners}
                                marginRight={2}
                            >
                                <Typography variant="h4">☰</Typography>
                            </Box>
                            <IconButton 
                                variant="contained" 
                                color="error" 
                                sx={{color: '#f44336 !important',":hover": {'color': '#f44336 !important'}}}
                                onClick={() => removeInputs(indexInput)}
                                disabled={isDisabled}
                            >
                                <CancelOutlined color='red' sx={{width: '30px !important',height: '30px !important'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                   <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                        <InputTextField 
                            name={`inputs[${indexInput}].name`}
                            control={control}
                            label={'Nome'}
                            rules={{ required: 'Este campo é obrigatório '}}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                        <InputTextField 
                            name={`inputs[${indexInput}].label`}
                            control={control}
                            label={'Label'}
                            rules={{ required: 'Este campo é obrigatório '}}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'left'}>
                        <Grid item xs={12} sm={6} md={5} lg={4} xl={4} sx={{alignItems: 'center', paddingBottom: '20px', marginRight: 2}}>
                            <FormControlLabel
                                value="right"
                                control={
                                    <Controller
                                        control={control}
                                        name={`inputs[${indexInput}].required`}
                                        render={({field: {value,...field}}) => (
                                            <Switch checked={value} {...field} />
                                        )}
                                        disabled={isDisabled}
                                    />
                                }
                                label="Campo Obrigatório?"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} sx={{alignItems: 'center', paddingBottom: '20px'}}>
                            <FormControlLabel
                                value="right"
                                onChangeCapture={() => setTriggerRender(!triggerRender)}
                                control={
                                    <Controller
                                        control={control}
                                        name={`inputs[${indexInput}].endpoint`}
                                        render={({field: {value,...field}}) => (
                                            <Switch checked={value} {...field} />
                                        )}
                                        disabled={isDisabled}
                                    />
                                }
                                label="Endpoint?"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                    {inputs?.[indexInput]?.endpoint === true ? 
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                                <InputTextField 
                                    name={`inputs[${indexInput}].endpointLabel`}
                                    control={control}
                                    label={'Label'}
                                    rules={{ required: 'Este campo é obrigatório '}}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                                <InputTextField 
                                    name={`inputs[${indexInput}].endpointValue`}
                                    control={control}
                                    label={'Value'}
                                    rules={{ required: 'Este campo é obrigatório '}}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                                <InputTextField 
                                    name={`inputs[${indexInput}].endpointRoute`}
                                    control={control}
                                    label={'Route'}
                                    rules={{ required: 'Este campo é obrigatório '}}
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>
                        
                        : nestedOptions(indexInput)
                        
                    }
                </Grid>
            );
        } else if (type === 'text' || type === 'textarea' || type === 'switch') {
            return (
                <Grid 
                    item xs={12} sm={12} md={4} lg={4} xl={4} key={`input${indexInput}`}
                    ref={ref} style={style}
                >
                    <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10.5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant='h4'>{`${optionsInputs.find((item) => item.value === type).label}`}</Typography>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} display={'flex'} alignItems={'center'}>
                            <Box 
                                style={{ cursor: 'grab' }}
                                {...attributes} {...listeners}
                                marginRight={2}
                            >
                                <Typography variant="h4">☰</Typography>
                            </Box>
                            <IconButton 
                                variant="contained" 
                                color="error" 
                                sx={{color: '#f44336 !important',":hover": {'color': '#f44336 !important'}}}
                                onClick={() => removeInputs(indexInput)}
                                disabled={isDisabled}
                            >
                                <CancelOutlined color='red' sx={{width: '30px !important',height: '30px !important'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                        <InputTextField 
                            name={`inputs[${indexInput}].name`}
                            control={control}
                            label={'Nome'}
                            rules={{ required: 'Este campo é obrigatório '}}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                        <InputTextField 
                            name={`inputs[${indexInput}].label`}
                            control={control}
                            label={'Label'}
                            rules={{ required: 'Este campo é obrigatório '}}
                            disabled={isDisabled}
                        />
                    </Grid>
                    {type !== 'switch' && 
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{alignItems: 'center', paddingBottom: '20px'}}>
                            <FormControlLabel
                                value="right"
                                control={
                                    <Controller
                                        control={control}
                                        name={`inputs[${indexInput}].required`}
                                        render={({field: {value,...field}}) => (
                                            <Switch checked={value} {...field} />
                                        )}
                                        disabled={isDisabled}
                                    />
                                }
                                label="Campo Obrigatório?"
                                labelPlacement="start"
                            />
                        </Grid>
                    }
                </Grid>
            );
        }
    }

    //Steps
    const { fields: fieldSteps, append: appendSteps, remove: removeSteps, /* update: updateSteps */ } = useFieldArray({
        control,
        name: 'steps',
    });

    const appendIds = (id, stepIndex) => setValue(`steps.${stepIndex}.fieldIds`, [...steps[stepIndex].fieldIds, id]);

    const removeIds = (idIndex, stepIndex) => setValue(`steps.${stepIndex}.fieldIds`, [...steps[stepIndex].fieldIds.filter((_, indexField) => indexField !== idIndex)]);

    const sectionFieldIds = (stepIndex, idIndex, ref, style, attributes, listeners) => {
        return (
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10} key={`fieldIds${idIndex}`}
                display={'flex'} justifyContent={'left'} alignItems={'center'}
                ref={ref} style={style} {...attributes} {...listeners}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputTextField 
                        name={`steps[${stepIndex}].fieldIds[${idIndex}].fieldId`}
                        key={`steps[${stepIndex}].fieldIds[${idIndex}].fieldId`}
                        control={control}
                        label={`FieldId ${idIndex + 1}`}
                        rules={{ required: 'Este campo é obrigatório '}}
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} marginBottom={3}
                    display={'flex'} justifyContent={'left'} alignItems={'center'} marginLeft={2}
                >
                    <Box 
                        style={{ cursor: 'grab' }}
                        {...attributes} {...listeners}
                        marginRight={2}
                        
                        // marginBottom={1.9}
                    >
                        <Typography variant="h4">☰</Typography>
                    </Box>
                    <IconButton 
                        variant="contained" 
                        color="error" 
                        sx={{color: '#f44336 !important',":hover": {'color': '#f44336 !important'}}}
                        onClick={() => {removeIds(idIndex, stepIndex)}}
                        disabled={isDisabled}
                    >
                        <CancelOutlined color='red' sx={{width: '30px !important',height: '30px !important'}} />
                    </IconButton>
                </Grid>
            </Grid>
        )
    };
    
    const nestedFieldIds = (stepIndex) => {

        return (
            <SortableContext
                items={steps[stepIndex].fieldIds.map((item) => item.id)}
                strategy={rectSortingStrategy}
                id={`sortableFieldIds-${stepIndex}`}
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginTop={2.1} zIndex={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {steps[stepIndex]?.fieldIds?.map((_, idIndex) => (
                            <SortableFieldIds key={idIndex} stepIndex={stepIndex} idIndex={idIndex} />
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Button 
                            type='button' 
                            onClick={() => 
                                appendIds({fieldId: '', id: `step-${stepIndex}-fieldId-${steps[stepIndex].fieldIds.length}`}, stepIndex)}
                            variant="contained" sx={{padding: '10px'}}
                            disabled={isDisabled}
                        >
                            <Typography variant='h5'>Add. FieldId</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </SortableContext>
        )
    }

    const handleDeleteSteps = (stepIndex) => {
        const prevSteps = [...steps];
        if (stepIndex !== fieldSteps.length -1) {
            removeSteps(stepIndex)
            setValue(`steps.[${stepIndex}].fieldIds`, [...prevSteps[stepIndex + 1].fieldIds]);
            
        } else {
            removeSteps(stepIndex)
        }
    }

    const sectionSteps = (stepIndex, ref, style, attributes, listeners) => {
        return (
            <>
                <Grid item xs={10} sm={10} md={6} lg={6} xl={6} 
                    display={'flex'} justifyContent={'left'} alignItems={'baseline'} zIndex={1}
                    ref={ref} style={style}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
                        display={'flex'} justifyContent={'left'} alignItems={'center'}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginTop={2}>
                            <InputTextField 
                                name={`steps[${stepIndex}].title`}
                                key={`steps[${stepIndex}].title`}
                                control={control}
                                label={`Step ${stepIndex + 1} Título`}
                                rules={{ required: 'Este campo é obrigatório '}}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2} 
                            display={'flex'} justifyContent={'left'} alignItems={'center'} marginLeft={2}
                        >
                            <Box 
                                style={{ cursor: 'grab' }}
                                {...attributes} {...listeners}
                                marginRight={2}
                                marginBottom={1.9}
                            >
                                <Typography variant="h4">☰</Typography>
                            </Box>
                            <IconButton 
                                variant="contained" 
                                color="error" 
                                sx={{color: '#f44336 !important',":hover": {'color': '#f44336 !important'}, marginBottom: 1.9}}
                                onClick={() => handleDeleteSteps(stepIndex)}
                                disabled={isDisabled}
                            >
                                <CancelOutlined color='red' sx={{width: '30px !important',height: '30px !important'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                {nestedFieldIds(stepIndex)}
            </>
        )
    }

    const arrangePayload = (data) => {
        const fieldsTreated = [];
        data.inputs.forEach((item) => {
            const newField = {};
            newField.type = item.type;
            newField.name = item.name;
            newField.label = item.label;
            if (item.required) {
                newField.rules = {required: {value: true, message: "Campo Obrigatório"}}
            }
            if (item.type === 'autocomplete' || item.type === 'autocompletetag') {
                if (item.endpoint) {
                    newField.endpoint = item.endpointRoute;
                    newField.fields = [item.endpointValue, item.endpointLabel];
                    newField.values = null;
                } else {
                    newField.values = item.options
                }
            }
            fieldsTreated.push(newField);
        });

        const stepsTreated = [];
        data.steps.forEach((item) => {
            const newStep = {};
            newStep.title = item.title;
            const fieldIds = []
            item.fieldIds.forEach(({fieldId}) => {
                const newFieldId = {};
                newFieldId.fieldId = fieldId;
                fieldIds.push(Object.values(newFieldId));
            })
            newStep.fieldsId = fieldIds.flat();
            stepsTreated.push(newStep);
        })

        const payloadData = {
            json_body: JSON.stringify({
                fields: fieldsTreated,
                steps: stepsTreated,
            }),
            titulo: data.nome,
            rota: data.rota 
        }

        return payloadData;

    }

    const submitCadastrar = async (data) => {
        try {
            const payloadData = arrangePayload(data);

            const payload = {
                data: [{
                    ...payloadData
                }]
            }

            const telaResponse = await axiosConfig.post("/backoffice/construct/cadastrar", payload);
            console.log(telaResponse);
            dispatch(showSnackbar('Tela cadastrada com sucesso', 'success'));

        } catch (error) {
            console.log(error);
            dispatch(showSnackbar('Erro ao cadastrar tela', 'error'));
        }
    }
    
    const submitEditar = async (data) => {
        try {
            const payloadData = arrangePayload(data);

            const originalData = {};
            originalData.titulo = initialData.titulo;
            originalData.rota = initialData.rota;
            originalData.json_body = initialData.json_body;

            const changedData = getChangedItems(originalData, payloadData);

            const payload = {
                data: [{...changedData, id: pathname.split('/').pop()}]
            };

            const editResponse = await axiosConfig.put('/backoffice/construct/editar', payload);
            console.log(editResponse);
            dispatch(showSnackbar('Tela editada com sucesso', 'success'));

        } catch (error) {
            console.log(error);
            dispatch(showSnackbar('Erro ao editar tela', 'error'));
        }
    }

    const onSubmit = async (data) => {
        switch(action) {
            case 'cadastrar':
                await submitCadastrar(data);
                break;
            case 'editar':
                await submitEditar(data);
                break;
            default:
                await submitCadastrar(data);
                break;
        }
    };

    const fetchData = async () => {
        const telaId = pathname.split('/').pop();
        const responseConstruct = await axiosConfig.get(`/backoffice/construct/listar?where[id]=${telaId}`);
        console.log(responseConstruct);

        const {nome, rota, json_body} = responseConstruct.data.data.objetos[0];
        const {fields, steps} = JSON.parse(json_body);

        const inputsTreated = [];
        fields.forEach((item, index) => {
            const input = {};
            input.type = item.type;
            input.name = item.name;
            input.label = item.label;
            input.id = `input-${index}`
            input.required = item.rules ? true : false;
            input.endpoint = item.endpoint ? true : false;
            if (item.endpoint) {
                input.endpointRoute = item.endpoint;
                input.endpointValue = item.fields[0];
                input.endpointLabel = item.fields[1];
            }
            input.options = item.values ? item.values : [];
            inputsTreated.push(input);
        });

        const stepsTreated = [];
        steps.forEach((item, index) => {
            const step = {};
            step.title = item.title;
            const fieldIds = [];
            item.fieldsId.forEach((id, idIndex) => {
                const fieldId = {};
                fieldId.fieldId = id;
                fieldId.id = `step-${index}-fieldId-${idIndex}`
                fieldIds.push(fieldId);
            });
            step.fieldIds = fieldIds;
            step.id = `step-${index}`;
            stepsTreated.push(step);
        });

        setValue('nome', nome);
        setValue('rota', rota);
        setValue('inputs', inputsTreated);
        if (steps.length > 0) setValue('hasSteps', true);
        setValue('steps', stepsTreated);
        setInitialData(responseConstruct.data.data.objetos[0]);
    }

    useEffect(() => {
        const onLoad = async () => {
            switch(action) {
                case 'editar':
                    setIsLoading(true);
                    setPageTitle('Editar Tela');
                    await fetchData();
                    setIsLoading(false);
                    break;
                case 'visualizar':
                    setIsLoading(true);
                    setIsDisabled(true);
                    setPageTitle('Visualizar Tela');
                    await fetchData();
                    setIsLoading(false);
                    break;
                default:
                    break;
            }
        };

        onLoad();
    }, []);

    const handleDragStart = ({active}) => {
        if (active.data.current.sortable.containerId.includes('sortableFieldIds')){
            setActiveId(parseInt(active.id.split('-')[3]));
        } else {
            setActiveId(parseInt(active.id.split('-')[1]));
        }
        const container = active.data.current?.sortable?.containerId;
        console.log(active)
        console.log(active.id)
        setActiveContainer(container);
    };

    const handleDragEnd = ({active, over}) => {
        if (!over) {
            setActiveId(null);
            setActiveContainer(null);
            return;
        }
    
        if (active.id !== over.id) {

            const activeContainer = active.data.current?.sortable?.containerId;
            const overContainer = over.data.current?.sortable?.containerId;
        
            if (activeContainer === overContainer) {
                if (activeContainer === 'sortableInputs') {
                    const oldIndex = parseInt(active.id.split('-')[1]);
                    const newIndex = parseInt(over.id.split('-')[1]);
                    setValue('inputs', arrayMove(inputs, oldIndex, newIndex));
                } else if (activeContainer === 'sortableSteps') {
                    const oldIndex = parseInt(active.id.split('-')[1]);
                    const newIndex = parseInt(over.id.split('-')[1]);
                    setValue('steps', arrayMove(steps, oldIndex, newIndex));
                } else if (activeContainer.includes('sortableFieldIds')) {
                    const stepIndex = parseInt(active.id.split('-')[1]);
                    const oldIndex = parseInt(active.id.split('-')[3]);
                    const newIndex = parseInt(over.id.split('-')[3]);
                    setValue(`steps[${stepIndex}].fieldIds`, arrayMove(steps[stepIndex].fieldIds, oldIndex, newIndex));
                }
            }
        }
        
        setActiveId(null);
        setActiveContainer(null);
    };

    const handleDragOver = ({active, over}) => {
        if (!over) return;

        const activeContainer = active.data.current?.sortable?.containerId;
        const overContainer = over.data.current?.sortable?.containerId;

        if (activeContainer !== overContainer) {
            return;
        }
    }

    const SortableInput = ({ value: {type}, index }) => {
        const {
          attributes,
          listeners,
          setNodeRef,
          transform,
          transition,
          isDragging
        } = useSortable({ id: `input-${index}`});
      
        const style = {
          transform: CSS.Transform.toString(transform),
          transition,
          opacity: isDragging ? 0.5 : 1,
          /* cursor: 'move' */
        };
      
        return selectedInput(type, index, setNodeRef, style, attributes, listeners)
    };

    const SortableStep = ({index}) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            isDragging
          } = useSortable({ id: `step-${index}`});
        
          const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            opacity: isDragging ? 0.5 : 1,
            /* cursor: 'move' */
          };
        
          return sectionSteps(index, setNodeRef, style, attributes, listeners)
    }

    const SortableFieldIds = ({stepIndex, idIndex}) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            isDragging
          } = useSortable({ id: `step-${stepIndex}-fieldId-${idIndex}`});
        
          const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            opacity: isDragging ? 0.5 : 1,
            /* cursor: 'move' */
          };
        
          return sectionFieldIds(stepIndex, idIndex, setNodeRef, style, attributes, listeners);
    }

    const renderDetails = () => (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
        >
            <Grid
                component={'form'}
                noValidate
                autoComplete='on'
                onSubmit={handleSubmit(onSubmit)}
                container
                justifyContent={'flex-start'}
                spacing={2}
            >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputTextField 
                        name={'nome'}
                        control={control}
                        label={'Nome'}
                        rules={{ required: 'Este campo é obrigatório '}}
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputTextField 
                        name={'rota'}
                        control={control}
                        label={'Rota'}
                        rules={{ required: 'Este campo é obrigatório '}}
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <InputAutocomplete 
                        name={'type'}
                        label={'Tipo Campo'}
                        control={control}
                        options={optionsInputs}
                        rules={{ required: 'Este campo é obrigatório '}}
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1} marginTop={'5px'}>
                    <Button 
                        type='button'
                        onClick={() => appendInputs({
                            type: type,
                            name: '',
                            label: '',
                            id: `input-${inputs.length}`,
                            required: false,
                            endpoint: false,
                            endpointRoute: '',
                            endpointLabel: '',
                            endpointValue: '',
                            options: []
                        })}
                        variant="contained" sx={{padding: '10px'}}
                        disabled={isDisabled}
                    >
                        <Typography variant='h5'>Adicionar</Typography>
                    </Button>
                </Grid>
                <Divider sx={{mt: 3,mb: 1,width: '100%'}} />
                <SortableContext
                    items={inputs.map((input) => input.id)}
                    strategy={rectSortingStrategy}
                    id='sortableInputs'
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexWrap={'wrap'} justifyContent={'left'}>
                        {fieldInputs?.map((input, index) => 
                            <SortableInput key={index} index={index} value={input} />
                        )}
                    </Grid>
                </SortableContext>
                <Divider sx={{mt: 3,mb: 1,width: '100%'}} />
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4">Ativar Stepper</Typography>
                        <FormControlLabel
                                value="right"
                                disabled={isDisabled}
                                control={
                                    <Controller
                                        control={control}
                                        name={`hasSteps`}
                                        render={({field: {value,...field}}) => (
                                            <Switch checked={value} {...field} />
                                        )}
                                    />
                                } //Verificar se da erro ao remover a label
                            />
                    </Grid>
                    {hasSteps && <Typography variant='h5'>Em FieldId você deve colocar o nome do input desejado</Typography>}
                    <Divider sx={{mt: 3,mb: 1,width: '100%'}} />
                    {hasSteps && 
                        <SortableContext
                            items={steps.map((step) => step.id)}
                            strategy={rectSortingStrategy}
                            id='sortableSteps'
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'left'} flexWrap={'wrap'}> 
                                {
                                    fieldSteps?.map((_, stepIndex) => (
                                        <>
                                            <SortableStep key={stepIndex} index={stepIndex} />
                                            <Divider sx={{mt: 3,mb: 1,width: '100%'}} />
                                        </>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                <Button 
                                    type='button' 
                                    onClick={() => appendSteps({title: '', fieldIds: [], id: `step-${steps.length}`})} 
                                    variant="contained" 
                                    sx={{padding: '10px', marginTop: 2}}
                                    disabled={isDisabled}
                                >
                                    <Typography variant='h5'>Add. Step</Typography>
                                </Button>
                            </Grid>
                        </SortableContext>
                    }
                </Grid>
                <Divider sx={{mt: 3,mb: 1,width: '100%'}} />
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <Button type='submit' variant="contained" sx={{padding: '10px'}} disabled={isDisabled}>
                        <Typography variant='h5'>Finalizar</Typography>
                    </Button>
                </Grid>
            </Grid>
            <DragOverlay>
                {(activeId || activeId === 0) && activeContainer === 'sortableInputs' && selectedInput(inputs[activeId].type, activeId)}
                {(activeId || activeId === 0) && activeContainer === 'sortableSteps' && sectionSteps(activeId)}
                {(activeId || activeId === 0) && activeContainer.includes('sortableFieldIds') && sectionFieldIds(activeContainer.split('-')[1], activeId)}
            </DragOverlay>
        </DndContext>
    );

    return (
        <ActionsLayout 
            title={pageTitle}
            renderDetails={renderDetails}
            loading={isLoading}
        />
    )
};

export default Actions;