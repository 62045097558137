import {Skeleton, useTheme} from "@mui/material";
import axiosConfig from "config/AxiosConfig";
import {useState,useEffect} from "react";
import Div from "ui-component/layouts/components/Div";

export const ImageToBlob=({arquivo, propState, field, setLoadingState = () => {}}) => {
    const {id,blob,titulo,extensao}=arquivo;
    const [blobUrl,setBlobUrl]=useState(null);
    const [isNotImage,setIsNotImage]=useState(false);
    const theme = useTheme();

    useEffect(() => {
        const fetchBlob=async () => {
            try {
                setLoadingState(true);
                if(blob) {
                    setBlobUrl(blob);
                    return;
                } else {
                    setIsNotImage(false);
                    if(!['png','jpg','jpeg'].some(ext => extensao.includes(ext))) return setIsNotImage(true);

                    const response=await axiosConfig.get(`/arquivos/arquivo/download/${id}`,{
                        responseType: 'blob'
                    });

                    const blob=new Blob([response.data],{type: response.headers['content-type']});

                    const fieldUpdated=propState.map((arquivo) => {
                        if(arquivo.id===id) {
                            return {...arquivo,blob: URL.createObjectURL(blob)};
                        }
                        return arquivo;
                    });

                    field.onChange(fieldUpdated);

                    setBlobUrl(URL.createObjectURL(blob));
                }
            } catch(e) {
                console.error(e);
            } finally {
                setLoadingState(false);
            }
        };

        fetchBlob();

        return () => {
            if(blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        }
    },[id,blob, extensao]);

    return (
        <>
            {
                isNotImage&&(
                    <Div sx={{width: '100%', height: '100%', bgcolor: theme.palette.primary.light, borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: '1.5rem', color: 'black'}}>{extensao}</p>
                    </Div>
                )
            }
            {
                !blobUrl&&!isNotImage&&( 
                        <Skeleton variant="rounded" width="100%" height="100%" />
                )
            }
            {
                blobUrl&& !isNotImage && (
                    <img src={blobUrl} alt={titulo} style={{width: '100%',height: '100%',objectFit: 'contain'}} />
                )
            }
        </>
    )
};