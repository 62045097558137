import {lazy} from 'react';

// project imports
import MainLayoutDash from 'layout/MainLayoutDash';
import Loadable from 'ui-component/Loadable';
import OKRs from 'views/pages/dashboard-clientes/OKRS';
import Clipping from 'views/pages/dashboard-clientes/Clipping';
import ClippingDetalhes from 'views/pages/dashboard-clientes/ClippingDetalhes';
import Atividades from 'views/pages/dashboard-clientes/Atividades';

// dashboard routing
const Planejamento=Loadable(lazy(() => import('views/pages/dashboard-clientes/Planejamento')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutesDash={
    path: '/',
    element: <MainLayoutDash />,
    children: [
        {
            path: '/',
            children: [
                {
                    path: 'dashboard/:empresa/planejamento',
                    element: <Planejamento />
                },
                {
                    path: 'dashboard/:empresa/okrs',
                    element: <OKRs />
                },
                {
                    path: 'dashboard/:empresa/clipping_geral',
                    element: <Clipping />
                },
                {
                    path: 'dashboard/:empresa/clipping_detalhes',
                    element: <ClippingDetalhes />
                },
                {
                    path: 'dashboard/:empresa/atividades_pinePr',
                    element: <Atividades />
                }
            ]
        }
    ]
};

export default MainRoutesDash;
