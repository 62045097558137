import {Box,Button,useMediaQuery,useTheme} from '@mui/material';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt';

import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers';
import {GlobalStyles} from '@mui/material';
import Div from 'ui-component/layouts/components/Div';
import {useDateFilterConfig} from 'contexts/DateFilterConfig';
import dayjs from 'dayjs';
import {useState} from 'react';

const locale='pt';

// ==============================|| SEARCH INPUT - MOBILE||============================== //


// ==============================|| SEARCH INPUT ||============================== //

const SearchSection=() => {
  const theme=useTheme();
  const isTablet=useMediaQuery(theme.breakpoints.down('md'));
  const {DateFilterConfig,setDateFilterConfig}=useDateFilterConfig();
  const [dates,setDates]=useState({startDate: DateFilterConfig.startDate,endDate: DateFilterConfig.endDate});

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <GlobalStyles
        styles={{
          '[class*="MuiPickersCalendarHeader-labelContainer"]': {
            color: 'black',
          },
          '[class*="MuiPickersDay-root"]': {
            color: 'black !important',
            '& .Mui-selected': {
              color: 'white !important',
              backgroundColor: `${theme.primaryPinePr} !important`
            },
            '&.Mui-selected': {
              color: 'white !important',
              backgroundColor: `${theme.primaryPinePr} !important`
            },
          },
          '[class*="MuiPickersYear-yearButton"]': {
            color: 'black !important',
          },
          '[class*="MuiInputBase-inputAdornedEnd"]': {
            color: 'black !important',
            fontSize: isTablet&&'0.8rem !important',
          },
        }}
      />
      <Box sx={{display: {xs: 'flex',md: 'none'},marginLeft: !isTablet&&'35px'}}>
        <DatePicker value={dates.startDate} label='Periodo Inicial' onChange={(e) => {
          // o startDate nao pode ser maior que o endDate
          if (dayjs(e).isAfter(dates.endDate)) {
            setDates((prev) => ({...prev, startDate: dates.endDate }));
            localStorage.setItem('startDate', JSON.stringify(dates.endDate));
          } else {
            setDates((prev) => ({...prev, startDate: e }));
            localStorage.setItem('startDate', JSON.stringify(e));
          }
        }} />
        <Div>-</Div>
        <DatePicker value={dates.endDate} label='Periodo Final' onChange={(e) => {
          // o startDate nao pode ser maior que o endDate
          if (dayjs(e).isBefore(dates.startDate)) {
            setDates((prev) => ({...prev, endDate: dates.startDate }));
            localStorage.setItem('endDate', JSON.stringify(dates.startDate));
          } else {
            setDates((prev) => ({...prev, endDate: e }));
            localStorage.setItem('endDate', JSON.stringify(e));
          }
        }} />
      </Box>
      <Box sx={{display: {xs: 'none',md: 'flex'},marginLeft: !isTablet&&'35px'}}>
        <DatePicker value={dates.startDate} label='Periodo Inicial' onChange={(e) => {
          // o startDate nao pode ser maior que o endDate
          if (dayjs(e).isAfter(dates.endDate)) {
            setDates((prev) => ({...prev, startDate: dates.endDate }));
            localStorage.setItem('startDate', JSON.stringify(dates.endDate));
          } else {
            setDates((prev) => ({...prev, startDate: e }));
            localStorage.setItem('startDate', JSON.stringify(e));
          }
        }} />
        <Div>-</Div>
        <DatePicker value={dates.endDate} label='Periodo Final' onChange={(e) => {
          // o startDate nao pode ser maior que o endDate
          if (dayjs(e).isBefore(dates.startDate)) {
            setDates((prev) => ({...prev, endDate: dates.startDate }));
            localStorage.setItem('endDate', JSON.stringify(dates.startDate));
          } else {
            setDates((prev) => ({...prev, endDate: e }));
            localStorage.setItem('endDate', JSON.stringify(e));
          }
        }} />
      </Box>
      {
        dates.startDate!==DateFilterConfig.startDate||dates.endDate!==DateFilterConfig.endDate? (
          <Button
            variant='contained'
            color='primary'
            sx={{marginLeft: '10px'}}
            onClick={() => {
              // o startDate nao pode ser maior que o endDate
              if (dates.startDate!==DateFilterConfig.startDate&&dates.endDate!==DateFilterConfig.endDate) {
                setDateFilterConfig((prev) => ({...prev, startDate: dates.startDate, endDate: dates.endDate }));
              }
              if (dates.startDate!==DateFilterConfig.startDate) {
                setDateFilterConfig((prev) => ({...prev, startDate: dates.startDate }));
              }
              if (dates.endDate!==DateFilterConfig.endDate) {
                setDateFilterConfig((prev) => ({...prev, endDate: dates.endDate }));
              }
            }}
          >
            Filtrar
          </Button>
        ):<></>
      }
    </LocalizationProvider>
  );
};

export default SearchSection;
