import {Link} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';

// material-ui
import {ButtonBase} from '@mui/material';

// project imports
import config from 'config';
import {MENU_OPEN} from 'store/actions';
import {useEffect} from 'react';
import LogoResoluti from 'assets/images/resolutiLogo.png';
import {useState} from 'react';
import {useContext} from 'react';
import {ConfigMenuContext} from 'contexts/ConfigMenu';
import {ThemeConfigContext} from 'contexts/themeConfig';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection=() => {
  const defaultId=useSelector((state) => state.customization.defaultId);
  const [Logo,setLogo]=useState(LogoResoluti);
  const dispatch=useDispatch();
  const menu = useContext(ConfigMenuContext);
  const themeConfig = useContext(ThemeConfigContext)

  useEffect(() => {
    try {
      let image = '';
      if(themeConfig.themeConfig==='light') {
        image=menu.response.config.logoMenu;
      } else {
        image=menu.response.config.logoMenuDark;
      }      
      setLogo(`${process.env.REACT_APP_BACKEND_URL}/${image}`);
    } catch (error) {
      console.error('Error em carregar a logo',error);
    }
  },[])

  return (
    <ButtonBase style={{width: '100%',height: '50%',transform: 'scale:(0.5)'}} disableRipple onClick={() => dispatch({type: MENU_OPEN,id: defaultId})} component={Link} to={config.defaultPath}>
      <img src={Logo} alt="Logo" width="50" />
    </ButtonBase>
  );
};

export default LogoSection;
