import React from 'react';
import {TextField,FormControl,FormHelperText,Box} from '@mui/material';
import InputMask from 'react-input-mask';
import {Controller} from 'react-hook-form';
import InputsFake from './InputsFake';
import getNestedValue from 'utils/getNestedValue';

export default function InputTextField({isFakeInput,name,control,label,rules,mask,maskChar,externalError,value,multiline,rows,disabled=false,type='text',onChangeFunction}) {
    const isError=(!!externalError||!!control._formState.errors[name]);

    const currentProps={}

    if(onChangeFunction) currentProps.onChange=onChangeFunction
    if(value) currentProps.value=value

    if(isFakeInput) {
        const value=getNestedValue(control._formValues,name);
        return <InputsFake type='text' label={label} value={value} rows={rows} />;
    }

    return (
        <FormControl fullWidth error={isError}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({field: {ref,...field}}) =>
                    mask? (
                        <InputMask {...field} disabled={disabled} mask={mask} maskChar={maskChar} inputRef={field.ref} ref={field.ref} itemRef={field.ref}>
                            {() => <TextField {...currentProps} InputLabelProps={{shrink: true}} innerRef={ref} label={label} fullWidth error={isError} disabled={disabled} />}
                        </InputMask>
                    ):(
                        <TextField {...field} {...currentProps} InputLabelProps={{shrink: true}} label={label} fullWidth error={isError} multiline={multiline} rows={rows} disabled={disabled} type={type} />
                    )
                }
            />
            <Box sx={{minHeight: '25px'}}>
                {isError&&(
                    <FormHelperText sx={{marginLeft: '0px'}}>
                        {externalError?.message||control._formState.errors[name]?.message}
                    </FormHelperText>
                )}
            </Box>
        </FormControl>
    );
}
