export function getChangedItems(initialObj,updatedObj) {
    const changedItems={};
    function compareObjects(initial,updated,base) {
      Object.keys(updated).forEach(key => {
        const initialValue=initial[key];
        const updatedValue=updated[key];

        // Verificar se é o campo 'id', exceto 'idRepiter'
        if(key.includes('id')&&key!=='idRepiter'|| key.includes('data')||key==='file' || key==='parcela' || key === 'parcelas' || key === 'mes_referencia' || key === 'primeiro_vencimento' || key === 'desconto' || key === 'vencimento' || key === 'valor') {
          base[key]=updatedValue;
          return; // Continue para o próximo campo
        }

        if(key==='idRepiter') {
          delete base[key];
        }

        // Verificar se é um objeto
        if(typeof updatedValue==='object'&&!Array.isArray(updatedValue)&&updatedValue!==null) {
          base[key]={};
          compareObjects(initialValue||{},updatedValue,base[key]);
          // Remove objetos vazios
          if(Object.keys(base[key]).length===0) {
            delete base[key];
          }
        }
        // Verificar se é um array
        else if(Array.isArray(updatedValue)) {
          if(!Array.isArray(initialValue)||JSON.stringify(initialValue)!==JSON.stringify(updatedValue)) {
            base[key]=updatedValue.map((item,index) => {
              // Se for um objeto, comparar recursivamente os objetos dentro do array
              if(typeof item==='object'&&item!==null) {
                const initialArrayItem=initialValue? initialValue[index]:{};
                const changedArrayItem={};
                compareObjects(initialArrayItem||{},item,changedArrayItem);
                return Object.keys(changedArrayItem).length>0? changedArrayItem:item;
              }
              return item; // Retornar o item se não for um objeto
            }).filter(item => Object.keys(item).length>0); // Remover arrays de objetos vazios
          }
        }
        // Comparação de valores simples
        else {
          let updatedValueConverted=updatedValue;

          // Converter boolean para 'Y'/'N'
          if(typeof updatedValue==='boolean') {
            updatedValueConverted=updatedValue? 'Y':'N';
          }

          if(initialValue!==updatedValueConverted) {
            base[key]=updatedValueConverted;
          }
        }
      });
    }

    compareObjects(initialObj,updatedObj,changedItems);

    return changedItems;
  }