import React from 'react';

// Material UI
import {Modal} from '@mui/material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

// Functional Components
import MainCard from 'ui-component/cards/MainCard';

// Libraries
import 'dayjs/locale/pt';
import {Box} from '@mui/system';

// Constants
const locale='pt';

export default function ActionsLayout({renderDetails, loading, title, overflow = 'hidden'}) {
  return (
    <MainCard title={title} elevation={3} sx={{overflow: overflow}}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        {renderDetails()}
      </LocalizationProvider>
      <Modal open={loading} sx={{zIndex: 99999999}}>
        <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh', zIndex: 999999}}>
          <div className="loader"></div>
        </Box>
      </Modal>
    </MainCard>
  );
}
