import React from 'react';
import PropTypes from "prop-types";
import {Card,CardHeader,CardContent} from "@mui/material";

const getColorObject = (color) => {

    if (typeof color === "string") {
        return {
            type: "color",
            value: color
        }
    }

    if (color.length === 1) {
        return {
            type: "color",
            value: color
        }
    }

    return {
        type: "gradient",
        value: color
    };
};

const getBgColorStyle = (colors, gradientDir) => {
    const colorObject = getColorObject(colors);
    if(!colorObject)
        return null;

    if(colorObject.type === "color") {
        return {backgroundColor: colorObject.value};
    }
    else if(colorObject.type === "gradient") {
        if(gradientDir)
            return {backgroundImage: `linear-gradient(${gradientDir}, ${colorObject.value.join()})`}
        return {backgroundImage: `linear-gradient(${colorObject.value.join()})`}
    }
};


const getBgImageStyle = (imgSrc) => {
    return {
        background: `url(${imgSrc}) no-repeat center`,
        backgroundSize: 'cover'
    }
};


const CardQuick=(
    {
        title,subheader,avatar,action,
        bgColor,bgImage,bgGradientDir,
        headerSx,noWrapper,wrapperSx,
        reverse,sx,children,
        ...restProps
    }) => {
    const [bgStyle,setBgStyle]=React.useState({});

    React.useEffect(() => {
        if(bgImage) {
            setBgStyle(getBgImageStyle(bgImage));
        } else if(bgColor) {
            setBgStyle(getBgColorStyle(bgColor,bgGradientDir));
        } else {
            setBgStyle({});
        }
    },[bgColor,bgImage,bgGradientDir]);

    return (
        <Card sx={{...bgStyle,position: "relative", borderRadius: '15px',boxShadow: '#5857597d 0px 0px 1.25rem 3px', ...sx}} {...restProps}>
            {
                (action||title||avatar)&&!reverse&&
                <CardHeader
                    title={title}
                    subheader={subheader}
                    action={action}
                    avatar={avatar}
                    titleTypographyProps={{
                        variant: 'h2',
                        fontSize: `20px !important`,
                        letterSpacing: 1.5
                    }}
                    sx={{
                        zIndex: 2,
                        position: "relative",
                        pb: 0,
                        height: '55px',
                        p: 0,
                        pt: 1,
                        pr: 1,
                        pl: 3,
                        ...headerSx,
                    }}
                />
            }
            {
                noWrapper? children:
                    <CardContent sx={{...wrapperSx,zIndex: 2,position: "relative"}}>{children}</CardContent>
            }
            {
                (action||title||avatar)&&reverse&&
                <CardHeader
                    title={title}
                    subheader={subheader}
                    action={action}
                    avatar={avatar}
                    sx={{
                        zIndex: 2,
                        height: '55px',
                        position: "relative",
                        borderBottomColor: (theme) => theme.palette.divider,
                        ...headerSx
                    }}
                />
            }
        </Card>
    );
};

CardQuick.defaultProps={
    backdropColor: "#000000",
    backdropOpacity: "0.7",
    backdrop: false,
    reverse: false,
    divider: false,
    noWrapper: false,
    headerSx: {},
};

CardQuick.propTypes={
    avatar: PropTypes.node,
    title: PropTypes.node,
    subheader: PropTypes.node,
    action: PropTypes.node,
    reverse: PropTypes.bool,
    variant: PropTypes.oneOf(["primary","secondary"]),
    bgColor: PropTypes.oneOfType([PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
    bgImage: PropTypes.string,
    divider: PropTypes.bool,
    backdropColor: PropTypes.oneOfType([PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
    backdropOpacity: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    backdrop: PropTypes.bool,
    noWrapper: PropTypes.bool,
};

export default CardQuick;
