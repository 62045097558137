import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import React from "react";

const getColorObject=(color) => {

    if(typeof color==="string") {
        return {
            type: "color",
            value: color
        }
    }

    if(color.length===1) {
        return {
            type: "color",
            value: color
        }
    }

    return {
        type: "gradient",
        value: color
    };
};

const getBgColorStyle=(colors,gradientDir) => {
    const colorObject=getColorObject(colors);
    if(!colorObject)
        return null;

    if(colorObject.type==="color") {
        return {backgroundColor: colorObject.value};
    }
    else if(colorObject.type==="gradient") {
        if(gradientDir)
            return {backgroundImage: `linear-gradient(${gradientDir}, ${colorObject.value.join()})`}
        return {backgroundImage: `linear-gradient(${colorObject.value.join()})`}
    }
};


export default function CardExample({title,children, sx, headerSx, bgColor, bgGradientDir, titleColor}) {
    const [bgStyle,setBgStyle]=React.useState({});

    React.useEffect(() => {
        if(bgColor) {
            setBgStyle(getBgColorStyle(bgColor,bgGradientDir));
        } 
    },[bgColor]);

    return (
        <Card sx={{borderRadius: '15px',boxShadow: '#5857597d 0px 0px 1.25rem 3px',height: '100%', ...sx, ...bgStyle}}>
            <CardHeader sx={{...headerSx}} title={<Typography variant={"h2"} mb={0} sx={{fontSize: 20,color: "black",letterSpacing: 1.5, ...titleColor}}>{title}</Typography>} />
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}