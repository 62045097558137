import axiosConfig from 'config/AxiosConfig';
import React,{createContext,useState,useContext} from 'react';
import {useEffect} from 'react';
import {useLocation} from 'react-router';

const DashboardPageContext=createContext();

export const useDashboardPage=() => useContext(DashboardPageContext);

export const DashboardPageProvider=({children}) => {
  const [data,setData]=useState([]);
  const [isLoading,setIsLoadingS]=useState(true);
  const [hasScrollbar, setHasScrollbar] = useState(false); // Verifica se tem scrollbar
  const pathname=useLocation().pathname

  const setIsLoading=(value) => {
    setHasScrollbar(false);
    setIsLoadingS(value);
  }

  useEffect(() => {
    if(pathname==='/dashboard-resumo') {
      const fetchData=async () => {
        try {
          setIsLoading(true);
          const response=await axiosConfig.get('dashboard/comercial/pinepr')
          setData(response.data?.data?.objetos[0]);
        } catch(e) {
          console.log(e);
          setData({
            propostas_fechadas_valor_head: [],
          });
        } finally {
          setIsLoading(false);
        }
      }
      fetchData();
    }
  },[pathname])

  return (
    <DashboardPageContext.Provider value={{
      data,isLoading, setIsLoading, hasScrollbar, setHasScrollbar
    }}>
      {children}
    </DashboardPageContext.Provider>
  );
};
