import {Link} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import {useLocation} from 'react-router';

// material-ui
import {ButtonBase} from '@mui/material';

// project imports
import {MENU_OPEN} from 'store/actions';
import LogoPinePR from '../../../assets/images/PinePr.jpg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection=() => {
  const defaultId=useSelector((state) => state.customization.defaultId);
  const dispatch=useDispatch();
  const pathname = useLocation().pathname;

  return (
    <ButtonBase style={{width: '100%',height: '50%',transform: 'scale:(0.5)'}} disableRipple onClick={() => dispatch({type: MENU_OPEN,id: defaultId})} component={Link} to={`/dashboard/${pathname.split('/')[2]}/planejamento`}>
      <img src={LogoPinePR} alt="Logo" width="100" height={60} />
    </ButtonBase>
  );
};

export default LogoSection;
