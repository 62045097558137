import React,{createContext,useState,useContext,useEffect} from 'react';
import {useConfigMenu} from './ConfigMenu';
import {io} from 'socket.io-client';
import {v4 as uuid} from 'uuid';

const SocketConfigContext=createContext();

export const useSocketConfig=() => useContext(SocketConfigContext);

export const SocketConfigProvider=({children}) => {
  const [socketIo,setSocketIo]=useState(null);
  const [notifications,setNotifications]=useState([]);
  const {user,response}=useConfigMenu();
  const [emitAlert,setEmitAlert]=useState(false);

  useEffect(() => {
    if(user) {

      let query={};

      query['userId']=user.id;
      query['urlTratada']=response?.config?.urlTratada;

      if(response?.config?.nodeJsSalas) {
        query['salas']=response.config.nodeJsSalas;
      }

      if(user?.nodeJsSalas) {
        query['salasUsuarios']=user.nodeJsSalas;
      }

      // const linkNodeJs=`${process.env.REACT_APP_SOCKET_URL}`||`${window.location.protocol}//${response?.config?.nodeJs}`;
      const linkNodeJs='http://localhost:3005';
      const socket=io(linkNodeJs,{
        transports: ['websocket','polling','flashsocket'],
        reconnection: false,
        query
      });

      socket.on('disconnect',function() {
        socket.io.reconnect();
      })

      socket.on('connect',function() {
      })

      // Onde recebo os sockets gerais

      socket.on(response?.config?.urlTratada,function(data) {
        console.log(data);
      });

      // Onde recebo os sockets do usuario logado

      socket.on(`${response?.config?.urlTratada}_${user.id}`,function(data) {
        console.log(data);
      });

      // sala asterisk

      if(response?.config?.asterisk) {
        socket.on(`${response.config.urlTratada}_asterisk`,function(data) {
          console.log(data);
        });
      }

      socket.on('notificacoesGerais',function(data) {
        console.log(data);
      });

      socket.emit('register',user.id);

      const localNotifications=localStorage.getItem('notificacoes')
      if(localNotifications) {
        const notificationsParsed=JSON.parse(localNotifications);
        setNotifications(notificationsParsed);
        if(document.title.includes('(')) {
          document.title=document.title.split(')')[1];
        }
        if (notificationsParsed.filter((item) => item.visualizada===false).length > 0) {
          document.title=`(${notificationsParsed.filter((item) => item.visualizada===false).length}) ${document.title}`;
        }
      }

      socket.on(
        // 'mensagem_usuario',
        'get_notifications',
        (notification) => {
          setNotifications((prevNotifications) => {
            const updatedNotifications=[notification,...prevNotifications];
            return updatedNotifications;
          });
          console.log('Todos notificações:',notification);
        });

      socket.on('notificacao_alert',(notification) => {
        let notificationsState=[]
        setNotifications((prevNotifications) => {
          const updatedNotifications=[notification,...prevNotifications];
          notificationsState=updatedNotifications;
          return updatedNotifications;
        });
        localStorage.setItem('notificacoes',JSON.stringify(notificationsState));
        if(document.title.includes('(')) {
          document.title=document.title.split(')')[1];
        }
        if (notificationsState.filter((item) => item.visualizada===false).length > 0) {
          document.title=`(${notificationsState.filter((item) => item.visualizada===false).length}) ${document.title}`;
        }
        if(notification.tipoMensagem==='alert') {
          setEmitAlert(true);
          setTimeout(() => {
            setEmitAlert(false);
          },4000);
        }
        console.log('Notificação recebida:',notification);
      });

      setSocketIo(socket);

      // socket.emit('register', user.id);

      // socket.emit('get_notifications', user.id);

      // socket.on('todas_notificacoes', (notifications) => {
      //   setNotifications(notifications);
      // });



      return () => {
        socket.disconnect();
      };
    }
  },[user])

  const sendAlertToUser=({paraId,paraNome,mensagem,titulo,icone,link,tipoMensagem,tipoAlerta}) => {
    socketIo.emit(
      // 'mensagem_usuario',
      'alert',
      {
        id: uuid(),
        paraId: paraId,
        deId: user.id,
        paraNome: paraNome,
        deNome: user.name,
        mensagem: mensagem,
        urlTratada: response.config.urlTratada,
        titulo: titulo,
        icone: icone,
        link: link,
        tipoMensagem: tipoMensagem,
        tipoAlerta: tipoAlerta,
        visualizada: false,
        date: new Date()
      });
  }

  const markAsViewed=({notificationId}) => {
    socketIo.emit('mark_as_viewed',{userId: user.id,notificationId});
    notifications.forEach((notification) => {
      if(notification.id===notificationId) {
        notification.visualizada=true;
      }
    });
    localStorage.setItem('notificacoes',JSON.stringify(notifications));
    if(document.title.includes('(')) {
      document.title=document.title.split(')')[1];
    }

    if (notifications.filter((item) => item.visualizada===false).length > 0) {
      document.title=`(${notifications.filter((item) => item.visualizada===false).length}) ${document.title}`;
    }
  };

  return (
    <SocketConfigContext.Provider value={{
      socketIo,notifications,markAsViewed,sendAlertToUser,emitAlert
    }}>
      {children}
    </SocketConfigContext.Provider>
  );
};
