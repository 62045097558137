// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { AttachFile, DoneOutline, FilePresent, LocalActivity, Psychology } from '@mui/icons-material';
import {useLocation} from 'react-router';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const pathname = useLocation().pathname;
  const items = [{
    id: 'home',
    title: 'Dashboard', 
    type: 'group',
    children: [{
      id: 'dashboard',
      title: 'Planejamento',
      type: 'item',
      url: `/dashboard/${pathname.split('/')[2]}/planejamento`,
      icon: <Psychology />
    },{
      id: 'okrs',
      title: 'OKRs',
      type: 'item',
      url: `/dashboard/${pathname.split('/')[2]}/okrs`,
      icon: <DoneOutline />
    }, {
      id: 'clipping',
      title: 'Clipping Geral',
      type: 'item',
      url: `/dashboard/${pathname.split('/')[2]}/clipping_geral`,
      icon: <AttachFile />
    }, {
      id: 'clipping_detalhes',
      title: 'Clipping Detalhes',
      type: 'item',
      url: `/dashboard/${pathname.split('/')[2]}/clipping_detalhes`,
      icon: <FilePresent />
    }, {
      id: 'atividades',
      title: 'Atividades PinePr',
      type: 'item',
      url: `/dashboard/${pathname.split('/')[2]}/atividades_pinePr`,
      icon: <LocalActivity />
    }]
  }]
  const navItems = items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
