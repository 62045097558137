import React from 'react';
import {Snackbar,Alert,Slide} from '@mui/material';
import {useSelector,useDispatch} from 'react-redux';
import {hideSnackbar} from '../store/SnackBar/actions';

const GlobalSnackbar=() => {
  const dispatch=useDispatch();
  const {open,message,status}=useSelector((state) => state.snackbar);

  const handleClose=() => {
    dispatch(hideSnackbar());
  };

  return (
    <>
      {
        open&&(
          <Snackbar
            TransitionComponent={(props) => <Slide {...props} direction="right" />}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top',horizontal: 'right'}}
            // Add the following prop to completely hide the Snackbar when closed
            // This will prevent it from staying in the system
            hideOnClose
          >
            <Alert onClose={handleClose} severity={status} sx={{width: '100%'}}>
              {message}
            </Alert>
          </Snackbar>
        )
      }
    </>
  );
};

export default GlobalSnackbar;
