import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import Actions from 'views/pages/backoffice/tela-construct/Actions';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin3 />
    },
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/registro',
      element: <AuthRegister3 />
    },
    {
      path: '/password',
      element: <AuthForgotPassword />
    },
    {
        path: "/tela-construct",
        element: <Actions />

    }

  ]
};

export default LoginRoutes;
