import PropTypes from 'prop-types';
import {forwardRef} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Card,CardContent,CardHeader,Divider,Typography,Button,Grid} from '@mui/material';
import {IconFilter} from '@tabler/icons';

// constant
// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard=forwardRef(
  (
    {
      border=true,
      boxShadow,
      children,
      padding,
      content=true,
      contentClass='',
      contentSX={},
      darkTitle,
      secondary,
      shadow,
      sx={},
      title,
      handleOpenFilterModal,
      openModal,
      filterContext,
      buttonFilter=false,
      ...others
    },
    ref
  ) => {
    const theme=useTheme();
    // const marginBottom = openModal ? '-20px !important' : ''
    const headerSX={
      // 'marginBottom': marginBottom,
      '& .MuiCardHeader-action': {mr: 0},
      '& .MuiCardHeader-title': {display: 'flex',justifyContent: 'space-between',alignItems: 'center'}
    };


    return (
      <Grid padding={padding? 0:2}>
        <Card
          ref={ref}
          {...others}
          sx={{
            border: border? '1px solid':'none',
            borderColor: theme.palette.primary[200]+25,
            boxShadow: '0px 0px 10px 5px rgb(32 40 45 / 8%)!important',
            ':hover': {
              boxShadow: boxShadow? shadow||'1px 0px 14px 10px rgb(32 40 45 / 8%)':'inherit'
            },
            backgroundColor: theme.palette.background.default,
            ...sx
          }}
        >
          {/* card header and action */}
          {title&&(
            <CardHeader
              sx={headerSX}
              title={
                darkTitle? (
                  <>
                    <Typography variant="h3">{title}</Typography>
                    {handleOpenFilterModal&& buttonFilter && <Button variant='outlined' startIcon={<IconFilter />} onClick={handleOpenFilterModal}>Filtros</Button>}
                  </>
                ):(
                  <>
                    {title}
                    {handleOpenFilterModal&& buttonFilter &&<Button variant='outlined' startIcon={<IconFilter />} onClick={handleOpenFilterModal}>Filtros</Button>}
                  </>
                )
              }
              action={secondary}
            />
          )}

          {
            openModal&&filterContext()
          }

          {/* content & header divider */}
          {title&& <Divider />}

          {/* card content */}
          {content&&(
            <CardContent sx={contentSX} className={contentClass}>
              {children}
            </CardContent>
          )}
          {!content&&children}
        </Card>
      </Grid>
    );
  }
);

MainCard.propTypes={
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node,PropTypes.string,PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node,PropTypes.string,PropTypes.object])
};

export default MainCard;
