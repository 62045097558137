import React
// ,{useEffect, useState} 
from 'react';
import {Autocomplete,TextField,FormControl,FormHelperText,Box,ListItemText} from '@mui/material';
import {Controller} from 'react-hook-form';
import InputsFake from './InputsFake';
import getNestedValue from 'utils/getNestedValue';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase()+string.substring(1);
}

export default function InputAutocomplete({name,isFakeInput,control,label,rules,options,defaultValue=null,externalError,disabled=false,readOnly=false,sx={},onChangeFunction=() => {},disableClearable = true, ...props}) {
  const isError=!!externalError||!!control._formState.errors[name];

  // const [visibleOptions,setVisibleOptions]=useState(10);

  // // Atualiza o número de itens visíveis ao rolar
  // const handleListboxScroll=(event) => {
  //   const listboxNode=event.currentTarget;
  //   if(listboxNode.scrollTop+listboxNode.clientHeight>=listboxNode.scrollHeight) {
  //     setVisibleOptions((prev) => Math.min(prev+10,options.length));
  //   }
  // };

  // // Atualiza o estado de opções quando as opções mudam
  // useEffect(() => {
  //   setVisibleOptions(10); // Reinicia para os 10 primeiros ao receber novas opções
  // },[options]);

  if (isFakeInput) {
    const value = options.find((option) => option.value===getNestedValue(control._formValues, name))?.label||'';
    return <InputsFake type='text' label={label} value={value} />;
  }

  return (
    <FormControl fullWidth error={isError} sx={sx}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({field: {onChange,value,...field}}) => (
          <Autocomplete
            {...field}
            disabled={disabled}
            readOnly={readOnly}
            disableClearable={disableClearable}
            // options={options.slice(0, visibleOptions)}
            options={options}
            getOptionLabel={(option) => capitalizeFirstLetter(option.label)}
            sx={{width: '100%'}}
            renderOption={(props,item) => (
              <li {...props} key={item.key}>
                <ListItemText>{item.label}</ListItemText>
              </li>
            )}
            label={label}
            ListboxProps={{
              // onScroll: handleListboxScroll,
              style: {
                paddingTop: '10px',
                border: '1px solid #e2e2e2',
                ...props.ListboxProps
              }
            }}
            renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} label={label} fullWidth error={isError} />}
            onChange={(_,newValue) => {
              onChange(newValue? newValue.value:null)
              onChangeFunction(newValue)
            }}
            value={options.find((option) => option.value===value)||null}
          />
        )}
      />
      <Box sx={{minHeight: '25px'}}>
        {isError&&(
          <FormHelperText sx={{marginLeft: '0px'}}>{externalError?.message||control._formState.errors[name]?.message}</FormHelperText>
        )}
      </Box>
    </FormControl>
  );
}
